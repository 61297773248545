/* eslint-disable no-undef */
import React, {
  useState, useEffect,
} from 'react';
import {
  Button, Box, FormControl, IconButton, MenuItem, Select, Typography, RadioGroup, FormControlLabel, Radio,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Header from '../Header';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { helperActions } from '../../../../store';
import NewTable from '../../../components/NewTable';
import { SwitchCustom } from './CustomComponent';
import ScheduleLocation from './ScheduleLocation';
import HealthConfirm from './HealthConfirm';
import EmailAddress from './EmailAddress';
import fetchApi from '../../../FetchApi';
import { getUserIds } from '../../../common/utils';

const useStyle = makeStyles((theme) => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  contentHeader: {
    margin: '1rem 0',
  },
  configBtn: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: '1rem',
  },
  performBtn: {
    backgroundColor: 'red',
    border: 'outset 5px red',
    width: '130px',
    color: 'white',
    '&:hover': {
      backgroundColor: 'red',
    },
    '&:disabled': {
      backgroundColor: 'gray',
      border: 'outset 5px gray',
      color: 'white',
    },
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0 10px 0',
    '& .switch-end': {
      flexGrow: '1',
      textAlign: 'end',
    },
  },
  timeForm: {
    display: 'flex',
    marginBottom: '1rem',
    alignItems: 'center',
    '& .select': {
      width: '6rem',
    },
    '& .switch-end': {
      flexGrow: '1',
      textAlign: 'end',
    },
  },
  separateForm: {
    display: 'flex',
    padding: '3px',
    alignItems: 'center',
  },
  addBtn: {
    textAlign: 'start',
  },
  checkBox: {
    color: '#ea8440',
    '&.Mui-checked': {
      color: '#ea8440',
    },
  },
  boxContainer: {
    margin: '0 20rem ',
    '& .positon-controller': {
      margin: '2rem 0',
    },
    [theme.breakpoints.down('lg')]: {
      margin: '0px',
    },
  },
  box: {
    margin: '2rem 0',
    [theme.breakpoints.down('md')]: {
      margin: '0 10rem ',
    },
  },
  buttonStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '1rem',
    },
  },
  subject: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportBack: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
  },
  cancelBtn: {
    backgroundColor: '#C0C0C0',
    color: 'white',
    width: '130px',
    marginRight: '1rem',
    border: 'outset 5px #C0C0C0',
    '&:hover': {
      backgroundColor: '#C0C0C0',
    },
  },
}));

const initHealthConfirmValues = {
  id: 0,
  days: [],
  time: '00:00',
  time_end: '00:00',
  snooze_time: 0,
  number_of_resends: 0,
};

const initScheduleValues = {
  id: 0,
  days: [],
  time_start: '00:00',
  time_end: '00:00',
};

const initEmailValues = {
  index: 0,
  enabled: false,
  email: '',
};

const AccountSetting = () => {
  const classes = useStyle();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [details, setDetails] = useState(false);

  const [getSchedule, setGetSchedule] = useState([initScheduleValues]);
  const [enabledIndexSchedule, setEnabledIndexSchedule] = useState([]);
  const [healthConfirm, setHealthConfirm] = useState([initHealthConfirmValues]);
  const [privateMode, setPrivateMode] = useState(true);
  const [enabledIndexHealthConfirm, setEnabledIndexHealthConfirm] = useState([]);
  const [email, setEmail] = useState([initEmailValues]);
  const [enabledIndexEmail, setEnabledIndexEmail] = useState([]);

  const [distance, setDistance] = useState(60);
  const [toggleLocation, setToggleLocation] = useState(false);
  const [toggleHealthConfirm, setToggleHealthConfirm] = useState(false);
  const [lowBattery, setLowBattery] = useState(false);
  const [locationSchedule, setLocationSchedule] = useState(false);
  const [nextScheduleId, setNextScheduleId] = useState(0);
  const [nextHealthConfirmId, setNextHealthConfirmId] = useState(0);
  const [enabled, setEnabled] = useState(true);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    removeColumn: [1],
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  const handleChange = (event, callback) => {
    callback(event.target.value);
  };

  // Call API setting user
  const handleSettingUser = async () => {
    const response = await fetchApi('/api/admin/settings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        position_check_schedule: {
          enabled,
          auto_get_lc_interval: distance,
          get_lc_schedule: enabledIndexSchedule,
          get_lc_schedule_notify: locationSchedule,
          get_private_mode: privateMode,
        },
        battery_check: {
          enabled: lowBattery,
          percent_alarm: 20,
        },
        // health_check_status: enabled,
        health_check_schedule: enabledIndexHealthConfirm,
        email_notification: enabledIndexEmail,
        userIds,
      }),
    });
    if (response.ok) {
      dispatch(helperActions.setPathname(location.pathname));
      navigate('/admin/finish');
    } else {
      console.log('false');
    }
    dispatch(helperActions.setLoading(false));
  };

  useEffect(() => {
    const updateArrayBasedOnChecked = (array) => array.filter((item) => item.checked === true || item.enabled === true);
    // const checkedGetSchedule = updateArrayBasedOnChecked(getSchedule);
    // const checkedHealCheck = updateArrayBasedOnChecked(healthConfirm);
    const checkedEmail = updateArrayBasedOnChecked(email);

    const newGetSchedule = getSchedule.map(({ checked, id, ...rest }) => rest);
    const newHealthConfirm = healthConfirm.map(({ checked, id, ...rest }) => rest);
    const newEmailCheck = checkedEmail.map(({ checked, index, ...rest }) => rest);

    setEnabledIndexSchedule(newGetSchedule);
    setEnabledIndexHealthConfirm(newHealthConfirm);
    setEnabledIndexEmail(newEmailCheck);
  }, [healthConfirm, email, getSchedule]);

  useEffect(() => {
    if (!details) {
      setDistance(60);
      setGetSchedule([initScheduleValues]);
      setHealthConfirm([initHealthConfirmValues]);
      setEmail([initEmailValues]);
      setToggleLocation(false);
      setToggleHealthConfirm(false);
      setLocationSchedule(false);
      setLowBattery(false);
      setNextScheduleId(0);
      setNextHealthConfirmId(0);
    }
  }, [details]);

  const handleDelete = (idToDelete, type) => {
    if (type === 'schedule') {
      const cloneGetSchedule = [...getSchedule];
      const updatedSchedule = cloneGetSchedule.filter((item) => item.id !== idToDelete);
      setGetSchedule(updatedSchedule);
    }
    if (type === 'healthConfirm') {
      const cloneHealthConfirm = [...healthConfirm];
      const updatedHealthConfirm = cloneHealthConfirm.filter((item) => item.id !== idToDelete);
      setHealthConfirm(updatedHealthConfirm);
    }
  };

  if (details) {
    return (
      <>
        <Header handleOnNavigate={() => { setDetails(false); }}>
          <div className={classes.contentHeader}>{t('monitorTargetDescription')}</div>
        </Header>
        <Box className={classes.boxContainer}>
          <div style={{ marginBottom: '5px' }}>{t('autoPositionDistance')}</div>
          <FormControl fullWidth className="positon-controller">
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={String(privateMode)}
              name="radio-buttons-group"
              onChange={(v) => setPrivateMode(v?.target?.value === 'true')}
            >
              <FormControlLabel value="false" control={<Radio />} label={t('accountSettingOn')} />
              <FormControlLabel value="true" control={<Radio />} label={t('accountSettingOff')} />
            </RadioGroup>
            <Select
              id="select-auto-position-distance"
              value={distance}
              defaultValue={60}
              style={{ backgroundColor: '#d7d7d7' }}
              onChange={(e) => handleChange(e, setDistance)}
            >
              <MenuItem value={10}>{t('10Seconds')}</MenuItem>
              <MenuItem value={60}>{t('1Minute')}</MenuItem>
              <MenuItem value={300}>{t('5Minute')}</MenuItem>
              <MenuItem value={600}>{t('10Minute')}</MenuItem>
              <MenuItem value={3600}>{t('60Minute')}</MenuItem>
              <MenuItem value={0}>{t('OFF')}</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.toggle}>
            <div className={classes.subject}>
              {t('locationSchedule')}
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: '20px' }} />
            </div>
            <div className="switch-end"><SwitchCustom onChange={(e) => setToggleLocation(e.target.checked)} disabled={distance === 0} /></div>
          </div>

          {toggleLocation && (
          <>
            {getSchedule.map((item) => <ScheduleLocation key={item.id} data={getSchedule} setData={setGetSchedule} callback={handleDelete} id={item.id} />)}
            <div className={classes.addBtn}>
              <Button
                disabled={getSchedule.length === 3}
                onClick={() => {
                  const cloneData = [...getSchedule];
                  const newId = nextScheduleId;
                  cloneData.push({ ...initScheduleValues, id: newId + 1 });
                  setGetSchedule(cloneData);
                  setNextScheduleId(newId + 1);
                }}
              >
                <ControlPointIcon sx={{ color: '#0EAA92' }} />
                <Typography color="#0EAA92">{t('addSchedule')}</Typography>
              </Button>
            </div>
          </>
          )}

          <div className={classes.toggle}>
            <div className={classes.subject}>{t('locationScheduleNotification')}</div>
            <div className="switch-end"><SwitchCustom checked={locationSchedule} onChange={(e) => setLocationSchedule(e.target.checked)} /></div>
          </div>

          <div className={classes.toggle}>
            <div className={classes.subject}>
              {t('notificationLowBatterty')}
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: '20px' }} />
            </div>
            <div className="switch-end"><SwitchCustom checked={lowBattery} onChange={(e) => setLowBattery(e.target.checked)} /></div>
          </div>

          <div className={classes.toggle}>
            <div className={classes.subject}>
              {t('healthSafetyConfirmation')}
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: '20px' }} />
            </div>
            <div className="switch-end"><SwitchCustom onChange={(e) => setToggleHealthConfirm(e.target.checked)} /></div>
          </div>

          {toggleHealthConfirm && (
          <>
            {healthConfirm.map((item) => <HealthConfirm key={item.id} data={healthConfirm} setData={setHealthConfirm} callback={handleDelete} id={item.id} />)}
            <div className={classes.addBtn}>
              <Button
                disabled={healthConfirm.length === 3}
                onClick={() => {
                  const cloneData = [...healthConfirm];
                  const newId = nextHealthConfirmId;
                  cloneData.push({ ...initHealthConfirmValues, id: newId + 1 });
                  setHealthConfirm(cloneData);
                  setNextHealthConfirmId(newId + 1);
                }}
              >
                <ControlPointIcon sx={{ color: '#0EAA92' }} />
                <Typography color="#0EAA92">{t('addSchedule')}</Typography>
              </Button>
            </div>
          </>
          )}

          <div style={{ marginBottom: '1rem' }}>{t('addNotiMail')}</div>
          {email.map((index) => <EmailAddress key={index.index} data={email} index={index.index} setData={setEmail} />)}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ textAlign: 'end', flexGrow: '1' }}>
              <IconButton
                disabled={email.length === 3}
                onClick={() => {
                  const cloneData = [...email];
                  cloneData.push({ ...initEmailValues, index: (cloneData.length) });
                  setEmail(cloneData);
                }}
              >
                <ControlPointIcon />
              </IconButton>
            </div>
          </div>
          <div className={classes.buttonStyle}>
            <Button className={classes.cancelBtn} onClick={() => setDetails(false)}>{t('sharedCancel')}</Button>
            <Button
              style={{ backgroundColor: '#ff0505', color: 'white', width: '130px', marginRight: '1rem', border: 'outset 5px #ff0505' }}
              onClick={handleSettingUser}
            >
              {t('perform')}
            </Button>
          </div>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header link="/admin/tools">
        <div className={classes.contentHeader}>{t('monitorTargetDescription')}</div>
      </Header>
      <NewTable {...dataTable} />
      <div className={classes.configBtn}>
        <Button
          disabled={dataSelect.length === 0}
          onClick={() => {
            setDetails(true);
          }}
          className={classes.performBtn}
          style={{ opacity: dataSelect.length === 0 ? 0.3 : 1 }}
        >
          {t('perform')}
        </Button>
      </div>
    </>
  );
};

export default AccountSetting;
