import React, { useState } from 'react';
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  useMediaQuery,
  useTheme,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Menu,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { helperActions, sessionActions } from '../store';
import { useTranslation } from '../common/components/LocalizationProvider';
import Logo from '../resources/images/TreLogo.svg';

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    height: '100%',
    display: 'flex',
  },
  mobileRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  desktopDrawer: {
    marginTop: '60px',
    backgroundColor: '#F09600',
    color: 'white',
    width: '20%',
    maxWidth: '300px',
    minWidth: '250px',
  },
  content: {
    flexGrow: 1,
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    marginTop: '60px',
  },
}));

const PageLayoutAdmin = ({ menu, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const user = useSelector((state) => state.session.user);

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAccount = () => {
    navigate('/admin/settingAccount');
    handleCloseUserMenu();
  };

  const handleLogout = async () => {
    dispatch(helperActions.unActive());
    await fetch('/api/session', { method: 'DELETE' });
    navigate('/login');
    handleCloseUserMenu();
    dispatch(helperActions.setAdminData({
      current: [],
      filter: [],
      orderBy: [],
      default: [],
    }));
    dispatch(sessionActions.updateUser(null));
  };

  return desktop ? (
    <div className={classes.desktopRoot}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: '60px', backgroundColor: '#F09600', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ height: 'inherit', display: 'flex', alignItems: 'center' }}>
          <div style={{
            backgroundSize: '78% 100%',
            backgroundColor: 'white',
            backgroundImage: `url(${Logo}) `,
            height: '100%',
            width: '200px',
          }}
          />
          <div style={{ fontSize: '2rem', marginLeft: '1rem', fontWeight: 'bold' }}>Admin</div>
        </div>
        <Box sx={{ flexGrow: 0 }}>
          <Button
            variant="text"
            onClick={handleOpenUserMenu}
            style={{ color: 'white', textTransform: 'lowercase', textDecoration: 'underline', fontSize: '1rem', marginRight: '2rem' }}
          >
            {user.name}
          </Button>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <List component="div" disablePadding>
              <ListItemButton onClick={handleAccount}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={t('settingsTitle')} />
              </ListItemButton>
              <ListItemButton onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={t('loginLogout')} />
              </ListItemButton>
            </List>
          </Menu>
        </Box>
      </AppBar>
      <Drawer
        variant="permanent"
        className={classes.desktopDrawer}
        classes={{ paper: classes.desktopDrawer }}
      >
        <div style={{ fontSize: '1.4rem', fontWeight: 'bold', padding: '5px', backgroundColor: '#F07000' }}>MENU</div>
        {menu}
      </Drawer>
      <div className={classes.content}>{children}</div>
    </div>
  ) : (
    <div className={classes.mobileRoot}>
      <Drawer
        variant="temporary"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{ paper: classes.desktopDrawer }}
        anchor="right"
      >
        {menu}
      </Drawer>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, height: '60px', backgroundColor: '#F09600', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ height: 'inherit', display: 'flex', alignItems: 'center' }}>
          <div style={{
            backgroundSize: '78% 100%',
            backgroundColor: 'white',
            backgroundImage: `url(${Logo}) `,
            height: '100%',
            width: '130px',
          }}
          />
          <div style={{ fontSize: '1.5rem', marginLeft: '0.5rem', fontWeight: 'bold' }}>Admin</div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flexGrow: 0 }}>
            <Button
              variant="text"
              onClick={handleOpenUserMenu}
              style={{
                color: 'white', textTransform: 'lowercase', textDecoration: 'underline', fontSize: '1rem', margin: ' 0 0.5rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100px',
              }}
            >
              {user.name}
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <List component="div" disablePadding>
                <ListItemButton onClick={handleAccount}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('settingsUser')} />
                </ListItemButton>
                <ListItemButton onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('loginLogout')} />
                </ListItemButton>
              </List>
            </Menu>
          </Box>
          <IconButton color="inherit" edge="start" sx={{ mr: 2, marginRight: '5px' }} onClick={() => setOpenDrawer(true)}>
            <MenuIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </div>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default PageLayoutAdmin;
