// eslint-disable-next-line import/no-named-as-default
import React, { useState, useEffect } from 'react';
import {
  Route, Routes, useLocation, useNavigate, useNavigation, useResolvedPath,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LinearProgress } from '@mui/material';
// import MainPage from './main/MainPage';
import RouteReportPage from './reports/RouteReportPage';
import ServerPage from './settings/ServerPage';
import UsersPage from './settings/UsersPage';
import DevicePage from './settings/DevicePage';
import UserPage from './settings/UserPage';
import NotificationsPage from './settings/NotificationsPage';
import NotificationPage from './settings/NotificationPage';
import GroupsPage from './settings/GroupsPage';
import GroupPage from './settings/GroupPage';
import PositionPage from './other/PositionPage';
import NetworkPage from './other/NetworkPage';
import EventReportPage from './reports/EventReportPage';
import ReplayPage from './other/ReplayPage';
import TripReportPage from './reports/TripReportPage';
import StopReportPage from './reports/StopReportPage';
import SummaryReportPage from './reports/SummaryReportPage';
import ChartReportPage from './reports/ChartReportPage';
import DriversPage from './settings/DriversPage';
import DriverPage from './settings/DriverPage';
import CalendarsPage from './settings/CalendarsPage';
import CalendarPage from './settings/CalendarPage';
import ComputedAttributesPage from './settings/ComputedAttributesPage';
import ComputedAttributePage from './settings/ComputedAttributePage';
import MaintenancesPage from './settings/MaintenancesPage';
import MaintenancePage from './settings/MaintenancePage';
import CommandsPage from './settings/CommandsPage';
import CommandPage from './settings/CommandPage';
import StatisticsPage from './reports/StatisticsPage';
import LoginPage from './login/LoginPage';
import RegisterPage from './login/RegisterPage';
import ResetPasswordPage from './login/ResetPasswordPage';
import GeofencesPage from './other/GeofencesPage';
import GeofencePage from './settings/GeofencePage';
import useQuery from './common/util/useQuery';
import { useEffectAsync } from './reactHelper';
import { devicesActions } from './store';
import EventPage from './other/EventPage';
import PreferencesPage from './settings/PreferencesPage';
import AccumulatorsPage from './settings/AccumulatorsPage';
import CommandSendPage from './settings/CommandSendPage';
import ActiveUserPage from './login/ActiveUserPage';
import DisasterPage from './disaster/DisasterPage';
import App from './App';
import ChangeServerPage from './other/ChangeServerPage';
import DevicesPage from './settings/DevicesPage';
import ScheduledPage from './reports/ScheduledPage';
import NotiPage from './help/NotiPage';
import Exception from './help/Exception';
import AlarmSetting from './alarm/AlarmSettingPage';
import AlarmPage from './alarm/AlarmPage';
// eslint-disable-next-line import/no-named-as-default
import MainPage from './main/MainPage';
import HistoryPage from './history/HistoryPage';
import ListTable from './admin/home/ListTable';
import EditPage from './admin/home/EditPage';
import DetailsTable from './admin/home/DetailsTable';
import Admin from './admin/Admin';
import Analys from './admin/analys/Analys';
import HistoryTable from './admin/home/HistoryTable';
import Finish from './admin/FinishPage';
import ResetPage from './admin/tools/components/reset/ResetPage';
// import GroupSettings from './admin/tools/components/newGroup/GroupSettings';
import ChangePassword from './admin/tools/components/change password/ChangePassword';
import MovingAccount from './admin/tools/components/moving account/MovingAccount';
import SendNotifications from './admin/tools/components/send notification/SendNotifications';
// START --update menu tool wireframe 4.0 - 2023/10/10
import RepresentAccount from './admin/tools/components/create account/RepresentAccount';
// END --update menu tool wireframe 4.0 - 2023/10/10
import SetupArea from './admin/tools/components/area/SetupArea';
import SettingAdminAccount from './admin/home/SettingAdminAccount';
// import GroupEdit from './admin/tools/components/newGroup/GroupEdit';
import ChangePasswordForm from './admin/tools/components/change password/ChangePasswordForm';
import ConfirmNotification from './admin/tools/components/send notification/ConfirmNotification';
import Tools from './admin/tools/Tools';
import AreaDetails from './admin/tools/components/area/AreaDetails';
import EditArea from './admin/tools/components/area/EditArea';
import CreateArea from './admin/tools/components/area/CreateArea';
import DetailsEditTable from './admin/home/DetailsEditTable';
import SuspensionOrResume from './admin/tools/components/suspension/SuspensionOrResume';
import AccountSetting from './admin/tools/components/monitor target/AccountSetting';
import PaymentPage from './payment/PaymentPage';
import LoginPaymentPage from './payment/LoginPaymentPage';
import { NoAuthLinkData } from './NoAuthLinkData';
import NavigateLocationSharingPage from './location_sharing/NavigateLocationSharingPage';

const Navigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirectsHandled, setRedirectsHandled] = useState(false);
  const { pathname, search } = useLocation();

  const query = useQuery();

  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    switch (pathname) {
      case '/admin/home':
        navigate('/admin/home/list');
        break;
      case '/admin/tools/setupArea/editArea':
        navigate('/adminsetupArea/editArea');
        break;
      case '/admin/tools/setupArea/areaDetails':
        navigate('/adminsetupArea/areaDetails');
        break;
      default:
        // code block
    }
  }, [navigate, pathname]);

  useEffectAsync(async () => {
    // const link = Object.values(NoAuthLinkData).find((listLink) => listLink === pathname);
    // console.log('Navigator path:', pathname, link, NoAuthLinkData);

    // if (link) {
    // navigate(link);
    // } else
    if (query.get('token')) {
      const token = query.get('token');
      await fetch(`/api/session?token=${encodeURIComponent(token)}`);
      navigate(pathname);
    } else if (query.get('deviceId')) {
      const deviceId = query.get('deviceId');
      const response = await fetch(`/api/devices?uniqueId=${deviceId}`);
      if (response.ok) {
        const items = await response.json();
        if (items.length > 0) {
          dispatch(devicesActions.selectId(items[0].id));
        }
      } else {
        throw Error(await response.text());
      }
      navigate('/admin');
    } else if (query.get('eventId')) {
      const eventId = parseInt(query.get('eventId'), 10);
      navigate(`/event/${eventId}`);
    } else {
      setRedirectsHandled(true);
    }
  }, [query]);

  if (!redirectsHandled) {
    return (<LinearProgress />);
  }

  return (
    <Routes>
      <Route path="/login-payment" element={<LoginPaymentPage setIsAuth={setIsAuth} />} />

      <Route path="/login" element={<LoginPage />} />
      {/* <Route path="/register" element={<RegisterPage />} /> */}
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      {/* <Route path="/change-server" element={<ChangeServerPage />} /> */}
      {/* <Route path="/activate/:activationCode" element={<ActiveUserPage />} /> */}
      <Route path="/location_sharing" element={<NavigateLocationSharingPage />} />
      <Route path="/" element={<App />}>
        {/* <Route index element={<MainPage />} /> */}
        <Route path="payment" element={<PaymentPage />} />
        {/* <Route path="position/:id" element={<PositionPage />} />
        <Route path="network/:positionId" element={<NetworkPage />} />
        <Route path="event/:id" element={<EventPage />} />
        <Route path="replay" element={<ReplayPage />} />
        <Route path="history" element={<HistoryPage />} />
        <Route path="geofences" element={<GeofencesPage />} />
        <Route path="disaster" element={<DisasterPage />} /> */}

        {/* <Route path="clientalarm">
          <Route index element={<NotiPage />} />
          <Route path="safetyconfirm/" element={<Exception />} />
        </Route>

        <Route path="help">
          <Route index element={<AlarmSetting />} />
          <Route path="soundbuzzer" element={<AlarmPage />} />
        </Route> */}

        <Route path="admin" element={<Admin />}>
          <Route path="home">
            <Route index path="list" element={<ListTable />} />
            <Route path="list/edit" element={<EditPage />} />
            <Route path="list/details/:id" element={<DetailsTable />} />
            <Route path="list/details/:id/edit" element={<DetailsEditTable />} />
            <Route path="list/details/history/:id" element={<HistoryTable />} />
          </Route>
          <Route path="analys" element={<Analys />} />
          <Route path="settingAccount" element={<SettingAdminAccount />} />
          <Route path="finish" element={<Finish />} />
          <Route path="tools">
            <Route index element={<Tools />} />
            <Route path="reset" element={<ResetPage />} />
            <Route path="setupArea" element={<SetupArea />} />
            <Route path="setupArea/createArea" element={<CreateArea />} />
            <Route path="setupArea/areaDetails/:id" element={<AreaDetails />} />
            <Route path="setupArea/editArea/:id" element={<EditArea />} />
            <Route path="accountSetting" element={<AccountSetting />} />
            {/* <Route path="groupsettings" element={<GroupSettings />} /> */}
            {/* <Route path="groupsettings/edit/:id" element={<GroupEdit />} /> */}
            <Route path="changepassword" element={<ChangePassword />} />
            <Route path="changePasswordForm/:userId" element={<ChangePasswordForm />} />
            <Route path="movingaccount" element={<MovingAccount />} />
            <Route path="suspensionorresume" element={<SuspensionOrResume />} />
            <Route path="sendnotifications" element={<SendNotifications />} />
            <Route path="sendnotifications/confirm" element={<ConfirmNotification />} />
            <Route path="representAccount" element={<RepresentAccount />} />
          </Route>
        </Route>

        {/* <Route path="settings">
          <Route path="accumulators/:deviceId" element={<AccumulatorsPage />} />
          <Route path="calendars" element={<CalendarsPage />} />
          <Route path="calendar/:id" element={<CalendarPage />} />
          <Route path="calendar" element={<CalendarPage />} />
          <Route path="commands" element={<CommandsPage />} />
          <Route path="command/:id" element={<CommandPage />} />
          <Route path="command" element={<CommandPage />} />
          <Route path="command-send/:deviceId" element={<CommandSendPage />} />
          <Route path="attributes" element={<ComputedAttributesPage />} />
          <Route path="attribute/:id" element={<ComputedAttributePage />} />
          <Route path="attribute" element={<ComputedAttributePage />} />
          <Route path="devices" element={<DevicesPage />} />
          <Route path="device/:id" element={<DevicePage />} />
          <Route path="device" element={<DevicePage />} />
          <Route path="drivers" element={<DriversPage />} />
          <Route path="driver/:id" element={<DriverPage />} />
          <Route path="driver" element={<DriverPage />} />
          <Route path="geofence/:id" element={<GeofencePage />} />
          <Route path="geofence" element={<GeofencePage />} />
          <Route path="groups" element={<GroupsPage />} />
          <Route path="group/:id" element={<GroupPage />} />
          <Route path="group" element={<GroupPage />} />
          <Route path="maintenances" element={<MaintenancesPage />} />
          <Route path="maintenance/:id" element={<MaintenancePage />} />
          <Route path="maintenance" element={<MaintenancePage />} />
          <Route path="notifications" element={<NotificationsPage />} />
          <Route path="notification/:id" element={<NotificationPage />} />
          <Route path="notification" element={<NotificationPage />} />
          <Route path="preferences" element={<PreferencesPage />} />
          <Route path="server" element={<ServerPage />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="user/:id" element={<UserPage />} />
          <Route path="user" element={<UserPage />} />
        </Route> */}

        {/* <Route path="reports">
          <Route path="chart" element={<ChartReportPage />} />
          <Route path="event" element={<EventReportPage />} />
          <Route path="route" element={<RouteReportPage />} />
          <Route path="stop" element={<StopReportPage />} />
          <Route path="summary" element={<SummaryReportPage />} />
          <Route path="trip" element={<TripReportPage />} />
          <Route path="scheduled" element={<ScheduledPage />} />
          <Route path="statistics" element={<StatisticsPage />} />
        </Route> */}
      </Route>
    </Routes>
  );
};

export default Navigation;
