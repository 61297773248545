import React from 'react';
import { List, ListItemIcon, MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HandymanIcon from '@mui/icons-material/Handyman';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#F0C000',
    '&.Mui-selected': {
      backgroundColor: '#F07000',
    },
    '&:hover, &:focus': {
      backgroundColor: '#F07000',
    },
  },
  menuIcon: {
    fontSize: '3.5rem', color: 'black',
  },
  menuTitle: {
    fontSize: '2rem', fontWeight: 'bold',
  },
  menuList: {
    '&.MuiList-root': {
      padding: '4px 0',
    },
  },
}));

// const MenuItem = ({
//   title, link, icon, selected,
// }) => (
//   <ListItemButton
//     key={link}
//     component={Link}
//     to={link}
//     selected={selected}
//     style={{
//       display: 'flex',
//       justifyContent: 'space-between',
//       backgroundColor: '#222A35',
//     }}
//   >
//     <ListItemIcon>{icon}</ListItemIcon>
//     <div style={{ fontSize: '1.5rem' }}>{title}</div>
//   </ListItemButton>
// );

const CustomMenuItem = ({ title, link, icon, selected }) => {
  const classes = useStyles();
  return (
    <MenuItem
      component={Link}
      to={link}
      selected={selected}
      className={classes.menuItem}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <div className={classes.menuTitle}>{title}</div>
    </MenuItem>
  );
};

const AdminMenu = () => {
  const t = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  return (
    <List className={classes.menuList}>
      <CustomMenuItem
        title={t('homepage')}
        link="/admin/home/list"
        icon={<HomeIcon className={classes.menuIcon} />}
        selected={location.pathname.startsWith('/admin/home/list')}
      />
      <div style={{ paddingBottom: '2px' }} />
      <CustomMenuItem
        title={t('tool')}
        link="/admin/tools"
        icon={<HandymanIcon className={classes.menuIcon} />}
        selected={location.pathname.startsWith('/admin/tools')}
      />
    </List>
  );
};

export default AdminMenu;
