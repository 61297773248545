/* eslint-disable no-restricted-syntax */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Typography, Snackbar,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useForm } from 'react-hook-form';
import Header from '../tools/components/Header';
import { useTranslation } from '../../common/components/LocalizationProvider';
import ConfirmationPopup from '../tools/components/ComfirmationPopup';
import ModalConfirm from '../modal/ModalConfirm';
import { helperActions } from '../../store';
import QrCode from '../components/QRCode';
import fetchApi from '../FetchApi';
import {
  RatePlan, CategoryAccount, ApplicationType, DetailMember, PaymentStatus,
} from '../common/DefineClass';
import NewTable from '../components/NewTable';
import {
  convertWidth, valueTranslate, DropdownEdit, encrypted,
} from '../common/utils';
import DatePickerEditor from '../components/DatePickerEditor';
import CustomDatePicker from '../components/CustomDatePicker';
import {
  billingMethod, paymentMethod, typeApplication, categoryType,
} from '../common/const';
import Vectorback from '../../resources/images/Vectorback.png';
import ModalSuccess from '../modal/ModalSuccess';

const userModel = new DetailMember();
const categoryAccount = new CategoryAccount();
const applicationType = new ApplicationType();
const paymentStatus = new PaymentStatus();
const ratePlan = new RatePlan();

const useStyle = makeStyles((theme) => ({
  qrcodeBox: {
    margin: '1rem 1rem 1rem 3rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: '1rem',
    },
  },
  backBtn: {
    backgroundColor: '#fff',
    display: 'flex',
    color: '#7f7f7f',
    border: '1px solid #7f7f7f',
    width: 'fit-content',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px',
  },
  createBtn: {
    backgroundColor: '#02AA9E',
    display: 'flex',
    color: 'white',
    width: 'fit-content',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px',
    '&:disabled': {
      backgroundColor: 'grey',
    },
    '&:hover': {
      backgroundColor: '#02AA9EB2',
      color: 'white',
    },
  },
  invitationCode: {
    fontSize: 20,
    fontWeight: 'bold',
    textDecoration: 'underline',
    color: '#222A35',
  },
  configTable: {
    height: '600px',
  },
}));

// declare column percentage
const listColumPercent = {
  category: 11,
  status: 10,
  name: 12,
  userId: 14,
  disabled: 7,
  email: 20,
  password: 10,
  representId: 9,
  startDate: 15,
  power: 8,
  battery: 10,
  longlat: 25,
  accquisitionTime: 15,
  actions: 6,
  ratePlan: 15,
  expirationDate: 15,
};

const DetailsEditTable = () => {
  const { control, watch, setFocus, formState: { isValid } } = useForm({
    mode: 'onChange',
  });

  const classes = useStyle();
  const gridRef = useRef();
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const boxRef = useRef();
  const { id } = useParams();

  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [changedRows, setChangedRows] = useState([]);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [manager, setManager] = useState(undefined);

  const [gridApi, getGridApi] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  const [statusTime, setStatusTime] = useState(1);
  const handleChangeRadioOption = (event, params) => {
    setStatusTime(parseInt(event.target.value, 10));
    params.api.refreshCells();
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const onCellValueChanged = useCallback((e) => {
    const dataOnRow = e.data;
    const oldItemIndex = e.rowIndex;

    const copyChangedRows = [...changedRows];
    const copyUsers = [...users];

    copyChangedRows[oldItemIndex] = dataOnRow;
    copyUsers[oldItemIndex] = dataOnRow;

    setChangedRows(copyChangedRows);
    setUsers(copyUsers);
  }, [changedRows, users]);

  const onCellEditRequest = useCallback((e) => {
    const dataOnRow = e.data;
    const { field } = e.colDef;
    const { newValue } = e;
    const oldItem = users.find((row) => row.id === dataOnRow.id);

    const newItem = { ...oldItem };
    newItem[field] = newValue;
    dispatch(helperActions.updateData(newItem));
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi(`/api/admin/members/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const userList = await response.json();
        const newData = [];
        const transformedUsersData = userList.map((data) => ({
          ...data,
          billingmethod: data.billing_method,
          userpaymentid: data.user_payment_id,
          lastbillingdate: data.last_billing_date,
          nextbillingdate: data.next_billing_date,
          updatedate: data.update_date,
          paymentmethod: data.payment_method,
          paymentnumber: data.payment_number,
          paymentspan: data.payment_span,
          rateplan: data.rate_plan,
          historyList: `${data.latitude},${data.longitude}`,
        }));
        transformedUsersData?.forEach((val) => {
          const cond = newData.find((data) => data.id === val.id);
          if (!cond) newData.push(val);
        });
        const represent = newData.find((data) => data.role === 1);
        const nullArray = new Array(newData.length).fill(null);
        setManager(represent);
        setUsers(newData);
        setOriginalUsers(newData);
        setChangedRows(nullArray);
      }
    };

    if (id) fetchData();
  }, [id]);

  const differentElements = changedRows.filter((element1) => {
    const existsInArray2 = originalUsers.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));

    return !existsInArray2;
  });

  const result = differentElements.filter((element) => element !== null);

  const representGroupId = users.find((user) => user.id === user.representationId)?.groupId;

  const updateData = result.map((item) => {
    const data = {
      id: item.id,
      name: item.name,
      email: item.email,
      payment_status_code: paymentStatus.newApplication,
      billingMethod: item.billingmethod,
      paymentMethod: item.paymentmethod,
      lastBillingDate: item.createDate !== null ? moment(item.createDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      nextBillingDate: item.endDate !== null ? moment(item.endDate).format('YYYY-MM-DD') : null,
      typeApp: typeApplication.find((type) => type.name === item.typeApp)?.value,
      note: item.note,
      category: categoryType.find((type) => type.name === item.category)?.value,
      category1: item.category1,
      category2: item.category2,
      rateplan: ratePlan.basic,
      enddate: item.id !== 0
        ? moment.utc(item.nextbillingdate || moment()).valueOf()
        : moment.utc(item.endDate || moment(), 'YYYY/MM/DD').valueOf(),
    };

    if (item.id === 0) {
      data.groupId = representGroupId;
      data.password = item.password;
      data.managerId = id;
      if (item.category === 1) {
        data.paymentMethod = billingMethod[2].value;
        data.billingMethod = paymentMethod[2].value;
      } else {
        data.billingMethod = billingMethod[0].value;
        data.paymentMethod = paymentMethod[0].value;
      }
    }

    return data;
  });
  const getRowHeight = useCallback((params) => (params.data.isNew ? 150 : 40), []);
  const detailsColumnDefs = [
    {
      headerName: t('categoryTableHeader1'),
      field: userModel.field1,
      width: convertWidth(listColumPercent.category),
      valueFormatter: (params) => valueTranslate(t, params.data.category1, 'category1'),
    },

    {
      headerName: t('categoryTableHeader2'),
      field: userModel.field2,
      width: convertWidth(listColumPercent.category),
      valueFormatter: (params) => valueTranslate(t, params.data.category2, 'category2'),
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field14,
      valueFormatter: (params) => {
        const fieldValue = params.data.isNew ? params.data.category : params.data[userModel.field14];
        if (params.data.isNew) console.log(fieldValue, 'fieldValue');

        return params.data.isNew
          ? valueTranslate(t, fieldValue, 'categoryAccount')
          : valueTranslate(t, fieldValue, 'account');
      },
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(categoryAccount.accountGeneral), value: categoryAccount.accountGeneral },
          { name: t(categoryAccount.accountSpecial), value: categoryAccount.accountSpecial },
        ],
      },
    },

    {
      headerName: t('nameTableHeader'),
      width: convertWidth(listColumPercent.name),
      field: userModel.field4,
      editable: true,
    },
    {
      headerName: t('userIdTableHeader'),
      width: convertWidth(listColumPercent.status),
      field: userModel.field5,
    },
    {
      headerName: t('userStatusTableHeader'),
      width: convertWidth(listColumPercent.disabled),
      field: userModel.field6,
      cellRenderer: (params) => {
        if (!params.data.isNew) {
          return valueTranslate(t, params.value, 'account');
        }
        return valueTranslate(t, false, 'account');
      },
    },
    {
      headerName: t('userEmailTableHeader'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field7,
      editable: true,
    },
    {
      headerName: t('passwordTableHeader'),
      width: convertWidth(listColumPercent.password),
      field: userModel.field15,
      editable: true,
    },
    {
      headerName: t('representIdTableHeader'),
      width: convertWidth(listColumPercent.representId),
      field: userModel.field9,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId, managedUserId } = data;
        return representationId || managedUserId;
      },
    },
    {
      headerName: t('applicationType'),
      field: userModel.field10,
      width: convertWidth(listColumPercent.ratePlan),
      valueFormatter: (params) => valueTranslate(t, params.data.typeApp, 'applicationType'),
      editable: ((params) => {
        if (params.data[userModel.field5] === 0) {
          return true;
        }
        return false;
      }),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t('mail'), value: applicationType.mail },
          { name: t('line'), value: applicationType.line },
          { name: t('ios'), value: applicationType.ios },
        ],
      },
    },
    {
      headerName: t('startDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field12,

      cellRenderer: (params) => {
        let inputDate = params.value;
        if (!inputDate) {
          inputDate = moment().valueOf();
        }
        const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
        return formattedDate;
      },
      editable: (params) => params.data[userModel.field5] === 0,
      cellEditor: DatePickerEditor,
    },
    {
      headerName: t('endDateTableHeader'),
      width: convertWidth(listColumPercent.expirationDate),
      field: userModel.field8,

      cellRenderer: (params) => {
        if (params.data[userModel.field5] !== 0) {
          const inputDate = params.data.next_billing_date;
          const formattedDate = inputDate ? moment(inputDate).local().format('YYYY/MM/DD') : '';
          return formattedDate;
        }
        const [statusTime, setStatusTime] = React.useState(params.data.statusTime || (params.data.endDate ? 1 : 0));
        const [selectedDate, setSelectedDate] = React.useState(params.data.endDate || null);

        React.useEffect(() => {
          if (params.data.endDate && !selectedDate) {
            setStatusTime(1);
            setSelectedDate(params.data.endDate);
          } else {
            setStatusTime(params.data.statusTime || 0);
          }
          setSelectedDate(params.data.endDate || null);
        }, [params.data.endDate, selectedDate]);

        const handleChangeRadioOption = (event) => {
          const selectedValue = parseInt(event.target.value, 10);
          setStatusTime(selectedValue);
          params.data.statusTime = selectedValue;

          if (selectedValue === 0) {
            params.data.endDate = null;
            setSelectedDate(null);
          }

          if (params.api) {
            params.api.stopEditing();
          }
        };

        const handleDateChange = (newDate) => {
          setSelectedDate(newDate);
          if (newDate) {
            setStatusTime(1);
            params.data.statusTime = 1;
            params.data.endDate = moment.utc(newDate).valueOf();
          } else {
            setStatusTime(0);
            params.data.endDate = null;
          }
          if (params.api) {
            params.api.stopEditing();
          }
        };

        const formattedDate = selectedDate ? moment(selectedDate).local().format('YYYY/MM/DD') : null;

        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="radio-buttons-group-label"
              value={statusTime}
              name={`radio-buttons-group-${params.node.id}`}
              onChange={(e) => handleChangeRadioOption(e)}
            >
              <FormControlLabel value={0} control={<Radio />} label={t('unLimited')} />
              <FormControlLabel value={1} control={<Radio />} label={t('limited')} />
            </RadioGroup>

            {statusTime === 1 && (
            <CustomDatePicker
              value={selectedDate}
              onChange={handleDateChange}
            />
            )}

            {formattedDate && <div>{formattedDate}</div>}
          </FormControl>
        );
      },
      editable: (params) => params.data[userModel.field5] === 0,
      cellEditor: DatePickerEditor,
    },
    {
      headerName: t('note'),
      width: convertWidth(listColumPercent.email),
      field: userModel.field11,
      editable: true,
    },
    {
      headerName: t('updateDateTableHeader'),
      width: convertWidth(listColumPercent.startDate),
      field: userModel.field13,
      cellRenderer: (params) => {
        const inputDate = params.value;
        const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
        return formattedDate;
      },
      editable: ((params) => {
        if (params.data[userModel.field5 === 0]) {
          return true;
        }
        return false;
      }),
    },
    {
      headerName: t('sharedDelete'),
      field: 'actions',
      width: convertWidth(listColumPercent.actions),
      cellRenderer: ((params) => (
        params.data[userModel.field5] ? (
          <Typography>-</Typography>
        ) : (
          <IconButton
            onClick={() => {
              const rowDisplay = [];
              params.api?.applyTransaction({ remove: [params.data] });
              params.api?.forEachNode((node) => {
                rowDisplay.push(node.data);
              });
              setUsers(rowDisplay);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        )
      )),
    },
  ];

  useEffect(() => {
    const copyChangedRows = [...changedRows];
    const differentElements = copyChangedRows.filter((element1) => {
      const existsInArray2 = users.some((element2) => JSON.stringify(element1) === JSON.stringify(element2));
      return !existsInArray2;
    });

    const result = differentElements.filter((element) => element !== null);

    // Xóa các phần tử trong mảng 'copyChangedRows' có giá trị tương đương với các phần tử trong mảng 'result'
    result?.forEach((item) => {
      const index = copyChangedRows.findIndex((element) => JSON.stringify(element) === JSON.stringify(item));
      if (index !== -1) {
        copyChangedRows.splice(index, 1);
      }
    });

    setChangedRows(copyChangedRows);
  }, [users]);

  const hasEmptyName = updateData.some((obj) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    if (obj.name === '' || obj.email === '') {
      return true;
    }

    if (obj.id === 0) {
      if (!passwordRegex.test(obj.password)) {
        return true;
      }
    }

    return false;
  });

  const handleSubmit = async () => {
    if (hasEmptyName) {
      handleOpenSnackbar();
    } else {
      // Password encryption
      updateData.forEach((item) => item.password = encrypted(item.password));

      const response = await fetchApi('/api/admin/payment_status/settings_v2', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateData),
      });
      if (response.ok) {
        dispatch(helperActions.setPathname(location.pathname));
        setOpenSuccess(true);
      }
    }
  };

  const dataTable = {
    getGridApi,
    newCustomeColumn: detailsColumnDefs,
    listData: users,
    tableRef: gridRef,
    boxRef,
    className: classes.configTable,
    floatingFilterOff: true,
    onCellEditRequest,
    singleClickEdit: true,
    suppressRowClickSelection: true,
    rowSelection: 'single',
    onCellValueChanged,
    getRowHeight,
    childrenBottom: (
      <div>
        <div className={classes.qrcodeBox}>
          <Typography className={classes.invitationCode}>{t('qrcode')}</Typography>
          <QrCode qrCodeData={manager?.inviteCode} />
          <Typography className={classes.invitationCode}>{t('invitationCode')}</Typography>
          <Typography fontSize={26}>{manager?.inviteCode}</Typography>
        </div>
      </div>
    ),
    childrenTop: (
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button
          sx={{ border: '1px solid #02AA9E', color: '#02AA9E', padding: 2, mb: 2 }}
          onClick={() => {
            const copyUsers = [...users];
            const copyOriginalUsers = [...originalUsers];
            copyUsers.push({
              category: categoryAccount.accountGeneral,
              name: '',
              id: 0,
              disabled: null,
              email: '',
              password: '',
              representationId: manager.representationId,
              accquisitionTime: '',
              createDate: null,
              endDate: null,
              actions: '',
              note: '',
              isNew: true,
              typeApp: applicationType.mail,
              category1: 0,
              category2: 1,
            });
            setUsers(copyUsers);
            setOriginalUsers(copyOriginalUsers);
          }}
        >
          <AddCircleIcon />
          <Typography ml={1}>{t('textAddAccountMember')}</Typography>
        </Button>
      </div>
    ),
  };

  return (
    <>
      <Header noDescription noBack topic="Create a manager account">
        <p style={{ fontWeight: 700, fontSize: '20px', margin: '2px 0' }}>{t('specicalAccountPageTitle')}</p>
        <div style={{ margin: '2px 0', display: 'flex' }}>
          <p>{t('descriptionHeaderRepresetive')}</p>
          <Button className={classes.backBtn} onClick={() => navigate('/admin/home/list')}>
            <img style={{ marginRight: '10px' }} src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
          <Button disabled={updateData.length === 0} className={classes.createBtn} onClick={() => setOpenModal(true)}>
            {t('sharedPerform')}
          </Button>
        </div>
      </Header>
      <ModalConfirm
        openModal={openModal}
        handleClose={handleCloseModal}
        action={handleSubmit}
        propsModal={{
          confirm: t('confirmCreateAccountMember'),
          titleCancel: t('cancelAddMember'),
          titleContinue: t('continueAccount'),
        }}
      />
      <NewTable {...dataTable} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
        message={t('editvalidate')}
        action={action}
      />
      <ModalSuccess
        openSuccess={openSuccess}
        handleClose={() => {
          setOpenSuccess(false);
          navigate('/admin/home/list');
        }}
        title={t('messageSuccess')}
      />
    </>
  );
};

export default DetailsEditTable;
