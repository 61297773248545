/* eslint-disable no-plusplus */
/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, LinearProgress, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import theme from './common/theme';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import BottomMenu from './common/components/BottomMenu';
import SocketController from './SocketController';
import CachingController from './CachingController';

import { useEffectAsync } from './reactHelper'; // v5.6
import { clientAlarmsActions, deviceTypeActions, sessionActions } from './store'; // v5.6
import AnpiLogo from './resources/images/Anpilogo.png';

const useStyles = makeStyles(() => ({
  page: {
    flexGrow: 1,
    overflow: 'auto',
  },
  menu: {
    zIndex: 1204,
  },
  alert: {
    backgroundColor: '#2C2C2C',
    color: '#FFFFFF',
  },
  logo: {
    maxHeight: '30px',
    width: '30px',
  },
}));

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const newServer = useSelector((state) => state.session.server.newServer);
  const initialized = useSelector((state) => !!state.session.user);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/admin/home/list');
    }
  }, [location.pathname]);

  const clientAlarms = useSelector((state) => state.clientAlarms.active);

  useEffectAsync(async () => {
    if (!initialized) {
      const response = await fetch('/api/session');
      if (response.ok) {
        dispatch(sessionActions.updateUser(await response.json()));
      } else if (newServer) {
        navigate('/register');
      } else {
        navigate('/login');
      }
    }
    const elements = document.getElementsByClassName('maplibregl-popup');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.display = 'none';
    }
    return null;
  }, [initialized, location]);

  useEffect(() => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      dispatch(deviceTypeActions.set('mobile'));
    } else {
      dispatch(deviceTypeActions.set('computer'));
    }
  // eslint-disable-next-line no-undef
  }, [navigator.userAgent]);

  return !initialized ? (<LinearProgress />) : (
    <>
      {/* <SocketController /> */}
      <CachingController />
      {clientAlarms && (
      <Alert
        icon={<img src={AnpiLogo} alt="warning" className={classes.logo} />}
        className={classes.alert}
        onClick={() => {
          dispatch(clientAlarmsActions.off());
          navigate('/clientalarm/safetyconfirm');
        }}
        style={{ position: 'fixed', zIndex: 9999, width: '100%', bottom: '0px', display: 'flex' }}
        severity="info"
      >
        Anpy【防災/子供老人みまもり】 安否確認が届いています。回答してください。
      </Alert>
      )}
      <div className={classes.page}>
        <Outlet />
      </div>
      {!desktop && (
        <div className={classes.menu}>
          {/* <BottomMenu /> */}
        </div>
      )}
    </>
  );
};

export default App;
