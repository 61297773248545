export const mockData = {
  data: [
    {
      id: 0,
      category: '一般',
      status: '継続課金',
      name: 'YUKIO',
      disabled: false,
      email: 'sample@example.com',
      role: 'MANAGER',
      paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
      paymentMethod: '代表者まとめて支払い',
      useStartDate: '2023/5/8',
      groups: [
        {
          id: 0,
          groupId: 0,
          groupName: 'OTHER',
          members: [
            {
              id: 1,
              category: '一般',
              name: 'hung',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
            {
              id: 2,
              category: '一般',
              name: 'hung1',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
            {
              id: 10,
              category: '一般',
              name: 'hung3',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
          ],
        },
        {
          id: 1,
          groupId: 1,
          groupName: 'YUKIO',
          members: [
            {
              id: 3,
              category: '一般',
              name: 'Viet1',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
            {
              id: 4,
              category: '一般',
              name: 'Viet2',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
            {
              id: 5,
              category: '一般',
              name: 'Viet2',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '代表者まとめて支払い',
              useStartDate: '2023/5/8',
            },
          ],
        },
      ],
    },
    {
      id: 1,
      category: '一般',
      status: '新規申込',
      name: 'かずや',
      disabled: true,
      email: 'sample3@example.com',
      role: 'MANAGER',
      paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
      paymentMethod: '自分で決済',
      useStartDate: '2023/5/21',
      groups: [
        {
          id: 2,
          groupId: 2,
          groupName: 'かずや',
          members: [
            {
              id: 'Dzf1b dfz8',
              category: '一般',
              name: 'さぶろう',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
          ],
        },
      ],
    },
    {
      id: 2,
      category: '一般',
      status: '解約',
      name: 'RYO',
      disabled: false,
      email: 'sample4@example.com',
      role: 'MANAGER',
      paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
      paymentMethod: '自分で決済',
      useStartDate: '2023/5/21',
      groups: [
        {
          id: 3,
          groupId: 3,
          groupName: 'RYO',
          members: [
            {
              id: 'Sadgnsbg7',
              category: '一般',
              name: 'いちう',
              disabled: false,
              role: 'user',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
          ],
        },
      ],
    },
    {
      id: 3,
      category: '特殊',
      status: '貸出中',
      name: '〇〇小学校',
      disabled: false,
      email: 'sample5@example.com',
      role: 'MANAGER',
      paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
      paymentMethod: '自分で決済',
      useStartDate: '2023/5/21',
      groups: [
        {
          id: 4,
          groupId: 4,
          groupName: '1組',
          members: [
            {
              id: 'Fbve78rhg',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
            {
              id: '9d8vb4we',
              category: '一般',
              name: '2班',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
          ],
        },
        {
          id: 5,
          groupId: 5,
          groupName: '2組',
          members: [
            {
              id: '95SBVFsB',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
            {
              id: 'SDBFSSD9',
              category: '一般',
              name: '2班',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
          ],
        },
        {
          id: 6,
          groupId: 6,
          groupName: '3組',
          members: [
            {
              id: '79gns7rysn',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,
              paymentId: 'abcdefghijABCDEFGHIJ1234567890abcd',
              paymentMethod: '自分で決済',
              useStartDate: '2023/5/21',
            },
          ],
        },
      ],

    },
  ],
  limit: 50,
  offset: 1,
  total: 591,
};

export const mockData2 = {
  data: [
    {
      id: 7,
      category: '一般',
      status: '継続課金',
      name: 'YUKIO2',
      disabled: false,
      email: 'sample@example.com',
      role: 'MANAGER',
      groups: [
        {
          id: 7,
          groupId: 7,
          groupName: 'OTHER',
          members: [
            {
              id: 8,
              category: '一般',
              name: 'hung',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
            },
            {
              id: 9,
              category: '一般',
              name: 'hung1',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
            },
            {
              id: 11,
              category: '一般',
              name: 'hung3',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
            },
          ],
        },
        {
          id: 8,
          groupId: 8,
          groupName: 'YUKIO321',
          members: [
            {
              id: 12,
              category: '一般',
              name: 'Viet1',
              disabled: false,
              role: 'user',
              email: 'Kei1@example.com',
              status: 'abc',
            },
            {
              id: 13,
              category: '一般',
              name: 'Viet2',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
            },
            {
              id: 14,
              category: '一般',
              name: 'Viet2',
              disabled: false,
              role: 'USER',
              email: 'Kei1@example.com',
              status: 'abc',
            },
          ],
        },
      ],
    },
    {
      id: 15,
      category: '一般',
      status: '新規申込',
      name: 'かずやfsds',
      disabled: true,
      email: 'sample3@example.com',
      role: 'MANAGER',
      groups: [
        {
          id: 9,
          groupId: 9,
          groupName: 'かずや',
          members: [
            {
              id: 'Dzf1b dfz8231',
              category: '一般',
              name: 'さぶろう',
              disabled: false,
              role: 'USER',
              email: null,
            },
          ],
        },
      ],
    },
    {
      id: 16,
      category: '一般',
      status: '解約',
      name: 'RYO',
      disabled: false,
      email: 'sample4@example.com',
      role: 'MANAGER',
      groups: [
        {
          id: 10,
          groupId: 10,
          groupName: 'RYO',
          members: [
            {
              id: 'Sadgnsbg7',
              category: '一般',
              name: 'いちう',
              disabled: false,
              role: 'user',
              email: null,
            },
          ],
        },
      ],
    },
    {
      id: 17,
      category: '特殊',
      status: '貸出中',
      name: '〇〇小学校',
      disabled: false,
      email: 'sample5@example.com',
      role: 'MANAGER',
      groups: [
        {
          id: 11,
          groupId: 11,
          groupName: '1組32',
          members: [
            {
              id: 'Fbve78rhg',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,

            },
            {
              id: '9d8vb4we',
              category: '一般',
              name: '2班',
              disabled: false,
              role: 'USER',
              email: null,
            },
          ],
        },
        {
          id: 12,
          groupId: 12,
          groupName: '2組',
          members: [
            {
              id: '95SBVFsB',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,
            },
            {
              id: 'SDBFSSD9',
              category: '一般',
              name: '2班',
              disabled: false,
              role: 'USER',
              email: null,
            },
          ],
        },
        {
          id: 13,
          groupId: 13,
          groupName: '3組',
          members: [
            {
              id: '79gns7rysn',
              category: '一般',
              name: '1班',
              disabled: false,
              role: 'USER',
              email: null,
            },
          ],
        },
      ],

    },
  ],
  limit: 50,
  offset: 51,
  total: 591,
};

export const listIDName = {
  data: [
    {
      name: 'YUKIO',
      id: 0,
    },
    {
      name: 'かずや',
      id: 1,
    },
    {
      name: 'YUKIO2',
      id: 2,
    },
    {
      name: '〇〇小学校',
      id: 3,
    },
    {
      name: 'YUKIO321',
      id: 4,
    },
    {
      name: 'YUKIO21',
      id: 5,
    },
    {
      name: 'YUKIO2111',
      id: 6,
    },
    {
      name: 'YUKIO231',
      id: 7,
    },
    {
      name: 'YUKIO32131232',
      id: 8,
    },
    {
      name: 'YUKIO143',
      id: 9,
    },
    {
      name: 'YUKIO43242',
      id: 10,
    },
    {
      name: 'YUKIO232',
      id: 11,
    },
    {
      name: 'YUKIO312231',
      id: 12,
    },
    {
      name: 'YUKIO123123312',
      id: 13,
    },
    {
      name: 'YUKIO343',
      id: 14,
    },
    {
      name: 'YUKIO33',
      id: 15,
    },
    {
      name: 'YUKIO22',
      id: 16,
    },
    {
      name: 'YUKIO11',
      id: 17,
    },
    {
      name: 'YUKIO1222',
      id: 18,
    },
    {
      name: 'YUKIO133',
      id: 19,
    },
    {
      name: 'YUKIO312',
      id: 20,
    },
    {
      name: 'YUKIO2311',
      id: 21,
    },
    {
      name: 'YUKIO111',
      id: 22,
    },
    {
      name: 'YUKIO222',
      id: 23,
    },
    {
      name: 'YUKIO333',
      id: 24,
    },
    {
      name: 'YUKIO44413',
      id: 25,
    },
    {
      name: 'YUKIO2321',
      id: 26,
    },
    {
      name: 'YUKIO32133333331',
      id: 27,
    },
    {
      name: 'YUKIO31',
      id: 28,
    },
    {
      name: 'YUKIO3219',
      id: 29,
    },
    {
      name: 'YUKI312O',
      id: 30,
    },
    {
      name: 'YUKI444O',
      id: 31,
    },
    {
      name: 'YUKIO32333',
      id: 32,
    },
    {
      name: 'YUKIO3311',
      id: 33,
    },
    {
      name: 'YUKIO21313',
      id: 34,
    },
    {
      name: 'YUKIO333312',
      id: 35,
    },
    {
      name: 'YUKIO321321',
      id: 36,
    },
    {
      name: 'YUKIO3213333',
      id: 37,
    },
    {
      name: 'YUKIO444',
      id: 38,
    },
    {
      name: 'YUKIO44421',
      id: 39,
    },
    {
      name: 'YUKIO5543',
      id: 40,
    },
    {
      name: 'YUKIO54654',
      id: 41,
    },
    {
      name: 'YUKIO5646',
      id: 42,
    },
    {
      name: 'YUKIO7686',
      id: 43,
    },
    {
      name: 'YUKIO87978',
      id: 44,
    },
    {
      name: 'YUKIO54654654',
      id: 45,
    },
    {
      name: 'YUKIO4535436',
      id: 46,
    },
    {
      name: 'YUKIO654564',
      id: 47,
    },
    {
      name: 'YUKIO54345354',
      id: 48,
    },
    {
      name: 'YUKIO53453',
      id: 49,
    },
    {
      name: 'YUKIO534665',
      id: 50,
    },
  ],
  limit: 50,
  offset: 51,
  total: 591,
};
export const listIDName2 = {
  data: [
    {
      name: 'HUNG',
      id: 0,
    },
    {
      name: 'かずや',
      id: 1,
    },
    {
      name: 'HUNG2',
      id: 2,
    },
    {
      name: '〇〇小学校',
      id: 3,
    },
    {
      name: 'HUNG3217',
      id: 4,
    },
    {
      name: 'HUNG21',
      id: 5,
    },
    {
      name: 'HUNG2111',
      id: 6,
    },
    {
      name: 'HUNG212331',
      id: 7,
    },
    {
      name: 'HUNG32131232',
      id: 8,
    },
    {
      name: 'HUNG143',
      id: 9,
    },
    {
      name: 'HUNG43242',
      id: 10,
    },
    {
      name: 'HUNG232',
      id: 11,
    },
    {
      name: 'HUNG312231',
      id: 12,
    },
    {
      name: 'HUNG123123312',
      id: 13,
    },
    {
      name: 'HUNG343',
      id: 14,
    },
    {
      name: 'HUNG33',
      id: 15,
    },
    {
      name: 'HUNG22',
      id: 16,
    },
    {
      name: 'HUNG11',
      id: 17,
    },
    {
      name: 'HUNG1222',
      id: 18,
    },
    {
      name: 'HUNG133',
      id: 19,
    },
    {
      name: 'HUNG312',
      id: 20,
    },
    {
      name: 'HUNG66231',
      id: 21,
    },
    {
      name: 'HUNG111',
      id: 22,
    },
    {
      name: 'HUNG222',
      id: 23,
    },
    {
      name: 'HUNG333',
      id: 24,
    },
    {
      name: 'HUNG44413',
      id: 25,
    },
    {
      name: 'HUNG324231',
      id: 26,
    },
    {
      name: 'HUNG32192',
      id: 27,
    },
    {
      name: 'HUNG31',
      id: 28,
    },
    {
      name: 'HUNG321111112',
      id: 29,
    },
    {
      name: 'YUKI312O',
      id: 30,
    },
    {
      name: 'YUKI444O',
      id: 31,
    },
    {
      name: 'HUNG32333',
      id: 32,
    },
    {
      name: 'HUNG3311',
      id: 33,
    },
    {
      name: 'HUNG21313',
      id: 34,
    },
    {
      name: 'HUNG333312',
      id: 35,
    },
    {
      name: 'HUNG321321',
      id: 36,
    },
    {
      name: 'HUNG3213333',
      id: 37,
    },
    {
      name: 'HUNG444',
      id: 38,
    },
    {
      name: 'HUNG44421',
      id: 39,
    },
    {
      name: 'HUNG5543',
      id: 40,
    },
    {
      name: 'HUNG54654',
      id: 41,
    },
    {
      name: 'HUNG5646',
      id: 42,
    },
    {
      name: 'HUNG7686',
      id: 43,
    },
    {
      name: 'HUNG87978',
      id: 44,
    },
    {
      name: 'HUNG54654654',
      id: 45,
    },
    {
      name: 'HUNG4535436',
      id: 46,
    },
    {
      name: 'HUNG654564',
      id: 47,
    },
    {
      name: 'YUKIO000054345354',
      id: 48,
    },
    {
      name: 'YUKI231O53453',
      id: 49,
    },
    {
      name: 'YU123KIO534665',
      id: 50,
    },
  ],
  limit: 50,
  offset: 51,
  total: 591,
};
export const subMockData = [
  {
    id: 0,
    category: '特殊',
    status: '継続課金',
    nickName: 'TRE1',
    useSt: '利用中',
    gmail: 'sample1@example.com',
    managerId: 'Sadg95SB',
    subManager: [
      {
        subManager: 'TRE1SUB1',
        subManagerId: 'Fbve78rhg',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB2',
        subManagerId: '9d8vb4we',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB3',
        subManagerId: 'Dzf1bdfz8',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
    ],
    permission: 'メイン管理者',
  },
  {
    id: 1,
    category: '特殊',
    status: '継続課金',
    nickName: 'TRE2',
    useSt: '利用中',
    gmail: 'sample2@example.com',
    managerId: 'Dzf1bWds',
    subManager: [
      {
        subManager: 'TRE1SUB1',
        subManagerId: 'Sadgnsbg7',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB2',
        subManagerId: '95SBVFsB',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB3',
        subManagerId: 'SDBFSSD9',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
    ],
    permission: 'メイン管理者',
  },
  {
    id: 2,
    category: '特殊',
    status: '継続課金',
    nickName: 'TRE3',
    useSt: '利用中',
    gmail: 'sample3@example.com',
    managerId: 'Fbvnb54t',
    subManager: [
      {
        subManager: 'TRE1SUB1',
        subManagerId: 'Wdsb498v5',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB2',
        subManagerId: 'Fmdnb54th',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
      {
        subManager: 'TRE1SUB3',
        subManagerId: '79gns7rysn',
        managerUseSt: '利用中',
        subManagerEmail: 'sample@example.com',
      },
    ],
    permission: 'メイン管理者',
  },
];

export const mimamoriMockData = [
  {
    id: 0,
    category: 'Special',
    status: 'On loan',
    nickName: '〇〇 elementary school',
    useSt: 'in use',
    gmail: 'sample@example.com',
    managerId: 'NT8tHUn3',
    groups: [
      {
        groupId: 1,
        groupName: '1組',
        data: [
          {
            userId: 'Fbve78rhg',
            category: '一般',
            targetName: '1班',
            userSt: '利用中',
            permission: 'サブ管理者',
            email: 'Kei1@example.com',
            power: null,
            batteryLevel: null,
            position: {
              latitude: null,
              longitude: null,
            },
            time: '2022.8.7 18:15',
            registerDate: '2023.4.29',
          },
          {
            userId: '9d8vb4we',
            category: '一般',
            targetName: '2班',
            userSt: '利用中',
            permission: 'みまもり対象',
            email: '',
            power: 'ON',
            batteryLevel: '37%',
            position: {
              latitude: '35.67544914201341',
              longitude: '139.73505496900017',
            },
            time: '2022.8.7 18:15',
            registerDate: '2023.4.29',
          },
          {
            userId: 'Dzf1bdfz8',
            category: '一般',
            targetName: '3班',
            userSt: '利用中',
            permission: 'みまもり対象',
            email: '',
            power: 'OFF',
            batteryLevel: '1%',
            position: {
              latitude: '35.67544914201341',
              longitude: '139.73505496900017',
            },
            time: '2022.8.7 17:48',
            registerDate: '2023.8.5',
          },
          {
            userId: 'Sadgnsbg7',
            category: '一般',
            targetName: '4班',
            userSt: '利用中',
            permission: 'みまもり対象',
            email: '',
            power: 'OFF',
            batteryLevel: '1%',
            position: {
              latitude: '35.67544914201341',
              longitude: '139.73505496900017',
            },
            time: '2022.8.7 17:48',
            registerDate: '2023.8.5',
          },
        ],
      },
    ],
    permission: 'メイン管理者',
    power: null,
    batteryLevel: null,
    position: {
      latitude: null,
      longitude: null,
    },
    time: '2022.8.7 18:15',
    registerDate: '2023.4.29',
  },
];

export const normalAcc = {
  totalManager: 100,
  totalSubManager: 30,
  totalUser: 135,
};

export const specialAcc = {
  totalManager: 98,
  totalSubManager: 44,
  totalUser: 152,
};

export const statusList = [
  {
    id: 0,
    name: 'editNewApplication',
  },
  {
    id: 1,
    name: 'editContinuousBilling',
  },
  {
    id: 2,
    name: 'editSupervise',
  },
  {
    id: 3,
    name: 'editCanceled',
  },
];

export const dataOfTable = [
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 25,
    name: 'hung',
    email: 'admin@gmail.com',
    map: '',
    category: 'special',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 26,
    name: 'hung',
    email: 'admin@gmail.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',
    email: 'admin@gmail.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',
    email: 'admin@gmail.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',
    email: 'admin@gmail.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',
    email: 'admin@gmail.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',
    email: 'hung@tksvietnam.com',
    category: 'special',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'special',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',

    category: 'normal',
    email: 'admin@gmail.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',

    category: 'normal',
    email: 'admin@gmail.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',

    category: 'normal',
    email: 'admin@gmail.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',

    category: 'normal',
    email: 'admin@gmail.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 24,
    name: 'hung',

    category: 'normal',
    email: 'admin@gmail.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 20,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt16@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 22,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt18@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'submanager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 5,
    groupName: 'SPECIAL-2',
    managerId: 19,
    creator: null,
  },
  {
    id: 21,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt17@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 19,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt15@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'manager',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 4,
    groupName: 'SPECIAL-1',
    managerId: 19,
    creator: null,
  },
  {
    id: 23,
    name: 'manhdt5',
    category: 'normal',
    email: 'manhdt19@tksvietnam.com',
    map: '',
    latitude: 0.0,
    longitude: 0.0,
    zoom: 0,
    expirationTime: null,
    deviceLimit: -1,
    userLimit: 0,
    phone: '',
    limitCommands: false,
    role: 'user',
    status: '',
    inviteCode: '',
    managedUserId: null,
    groupId: 6,
    groupName: 'SPECIAL-3',
    managerId: 23,
    creator: null,
  },
];
