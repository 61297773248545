import React, {
  forwardRef, useEffect, useState, useRef, useImperativeHandle,
} from 'react';
import { makeStyles } from '@mui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { helperActions } from '../../store';

const useStyles = makeStyles({
  datePicker: {
    width: '100%',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '4px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const CustomDateComponent = forwardRef((props, ref) => {
  const { field } = props.column.colDef;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const refDatePicker = useRef();
  const refInput = useRef();

  const adminData = useSelector((state) => state.helper.adminData);

  const handleFilterDate = (selectedDate) => {
    const start = moment(selectedDate).startOf('day');
    const end = moment(selectedDate).endOf('day');

    const startTimestamp = start.valueOf();
    const endTimestamp = end.valueOf();

    const copyData = { ...adminData };
    const filter = [...copyData.filter];
    const existingFilterIndex = filter.findIndex((filterField) => filterField.name === field);
    if (existingFilterIndex === -1) {
      filter.push({ name: field, type: '07', value: startTimestamp, optionalValue: endTimestamp, dataType: 'date' });
      copyData.filter = filter;
      dispatch(helperActions.setAdminData(copyData));
    } else {
      const copyFilter = [...filter];
      copyFilter[existingFilterIndex] = { ...filter[existingFilterIndex], value: startTimestamp, optionalValue: endTimestamp };
      dispatch(helperActions.setAdminData({ ...copyData, filter: copyFilter }));
    }
  };

  const onDateChanged = (selectedDate) => {
    setSelectedDate(selectedDate);
    handleFilterDate(selectedDate);
  };

  useImperativeHandle(ref, () => ({
    getDate() {
      return selectedDate;
    },

    setDate(date) {
      setSelectedDate(date);
    },
  }));

  return (
    <div ref={refInput} className="ag-input-wrapper">
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        className={classes.datePicker}
        selected={selectedDate}
        dateFormat="yyyy/MM/dd"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={onDateChanged}
      />
    </div>
  );
});

export default CustomDateComponent;
