/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';
import {
  IconButton, Pagination, TablePagination,
} from '@mui/material';
import moment from 'moment';
import { helperActions } from '../../store';
import Table from './Table';
import { useTranslation } from '../../common/components/LocalizationProvider';
import fetchApi from '../FetchApi';
import UseFilter from './UseFilter';
import FloatingFilterComponent from './FloatingFilterComponent';
import {
  Member, PaymentStatus, BillingMethod, RatePlan, PaymentMethod, PaymentSpan, Type,
} from '../common/DefineClass';
import {
  convertWidth, valueTranslate, DropdownEdit,
} from '../common/utils';
import CheckBoxAll from './CheckBoxAll';
import DatePicker from './DatePicker';
import DatePickerEditor from './DatePickerEditor';

const useStyle = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
  },
  pagination: {
    paddingTop: '10px',
    '& .MuiPaginationItem-root': {
      '&.Mui-selected': {
        backgroundColor: '#f47024',
      },
    },
  },
  link: {
    color: '#1e70c4',
  },
}));

const detailButton = (data) => {
  const classes = useStyle();
  const t = useTranslation();
  return (
    <Link
      underline="always"
      className={classes.link}
      to={`/admin/home/list/details/${data.representationId || data.managedUserId}/edit`}
    >
      {t('detailTableHeader')}
    </Link>
  );
};

// declare column percentage
const listColumPercent = {
  checkbox: 4,
  detail: 6,
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  groupName: 10,
  representId: 9,
  paymentId: 22,
  billingMethod: 15,
  ratePlan: 15,
  paymentMethod: 20,
  paymentSpan: 15,
  price: 8,
  lastBillingDate: 10,
  nextBillingDate: 10,
  startDate: 10,
  phone: 10,
  withdrawDate: 10,
  updateDate: 10,
  type: 7,
};

const NewTable = (props) => {
  const {
    removeAllColumn,
    removeColumn,
    listData,
    editable,
    editGroup,
    changePasswordById,
    changeGroupById,
    newCustomeColumn,
    className,
    saveData,
    editStatus,
    showPagination,
    getElementRef,
    setGridApi,
    listChecked,
    refreshData,
    setRefreshData,
    ...others
  } = props;

  const classes = useStyle();
  const mainRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation();
  const navigate = useNavigate();

  const userModel = new Member();
  const paymentStatus = new PaymentStatus();
  const billingMethod = new BillingMethod();
  const ratePlan = new RatePlan();
  const paymentMethod = new PaymentMethod();
  const paymentSpan = new PaymentSpan();

  const filterData = useSelector((state) => state.helper.adminData.filter);
  const direct = useSelector((state) => state.helper.adminData.orderBy);

  const [data, setData] = useState([]);
  const [listNode, setListNode] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(50);
  const [checked, setChecked] = useState(false);

  const count = Math.ceil(totalPages / limit);

  // Declare columns
  const columnDefs = [
    {
      width: convertWidth(listColumPercent.checkbox),
      field: 'isChecked',
      filter: false,
      // headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponentFramework: (() => (
        <CheckBoxAll
          setCheck={setChecked}
        />
      )),
    },
    {
      headerName: t('detailTableHeader'),
      width: convertWidth(listColumPercent.detail),
      cellRenderer: (params) => detailButton(params.data),
      field: userModel.field1,
      filter: false,
    },
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      floatingFilterComponent: null,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Category Filters',
        values: [{ value: 1, category: 'account' }, { value: 0, category: 'account' }],
      },
    },
    {
      headerName: t('statusTableHeader'),
      field: userModel.field3,
      width: convertWidth(listColumPercent.status),
      floatingFilterComponent: null,
      valueFormatter: (params) => valueTranslate(t, params.data.paymentstatuscode, 'paymentStatus'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: paymentStatus.newApplication, category: 'paymentStatus' },
          { value: paymentStatus.continousBilling, category: 'paymentStatus' },
          { value: paymentStatus.reminder, category: 'paymentStatus' },
          { value: paymentStatus.cancelationLaw, category: 'paymentStatus' },
        ],
      },
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentStatus.newApplication), value: paymentStatus.newApplication },
          { name: t(paymentStatus.continousBilling), value: paymentStatus.continousBilling },
          { name: t(paymentStatus.reminder), value: paymentStatus.reminder },
          { name: t(paymentStatus.cancelationLaw), value: paymentStatus.cancelationLaw },
        ],
      },
    },
    {
      headerName: t('nameTableHeader'),
      field: userModel.field4,
      width: convertWidth(listColumPercent.name),
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('userIdTableHeader'),
      field: userModel.field5,
      filterParams: {
        type: 'NUMBER',
      },
      width: convertWidth(listColumPercent.userId),
      cellRenderer: ((params) => (
        <div key={params?.data?.id} style={{ display: 'flex', alignItems: 'center' }}>
          {params?.data?.id}
          {changePasswordById && params.data.providerType === 0 && (
          <div style={{ display: 'flex', position: 'absolute', right: 3, minHeight: '50px' }}>
            <IconButton
              sx={{ color: 'black' }}
              size="small"
              onClick={() => {
                navigate(`/admin/tools/changePasswordForm/${params?.data?.id}`);
                dispatch(helperActions.getChangePasswordUser(params?.data));
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
          )}
        </div>
      )),
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('userStatusTableHeader'),
      field: userModel.field6,
      width: convertWidth(listColumPercent.disabled),
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
      filter: UseFilter,
      filterParams: {
        title: 'Status Filters',
        values: [
          { value: false, category: 'account' },
          { value: true, category: 'account' },
          // { value: '退会済', category: 'account' },
        ],
      },
      floatingFilterComponent: null,
    },
    {
      headerName: t('userEmailTableHeader'),
      field: userModel.field7,
      width: convertWidth(listColumPercent.email),
      floatingFilterComponent: FloatingFilterComponent,
    },
    // {
    //   headerName: t('groupTableHeader'),
    //   field: userModel.field8,
    //   width: convertWidth(listColumPercent.groupName),
    //   floatingFilterComponent: FloatingFilterComponent,
    //   cellRenderer: ((params) => (
    //     <div key={params?.data?.groupName} style={{ display: 'flex', alignItems: 'center' }}>
    //       {params?.data?.groupName || '-'}
    //       {changeGroupById && (
    //       <div style={params.data.groupName ? { display: 'flex', position: 'absolute', right: 3 } : { display: 'flex', position: 'absolute', right: 3, top: 5 }}>
    //         <IconButton
    //           sx={{ color: 'black' }}
    //           size="small"
    //           onClick={() => {
    //             dispatch(helperActions.setGroupEditData({ select: params.data, current: params?.data }));
    //             if (params?.data?.representationId || params?.data?.managedUserId !== 0) {
    //               navigate(`edit/manager=${params?.data?.representationId || params?.data?.managedUserId}&group=${params?.data?.groupId}`);
    //             } else {
    //               navigate('404');
    //             }
    //           }}
    //         >
    //           <EditIcon />
    //         </IconButton>
    //       </div>
    //       )}
    //     </div>
    //   )),
    // },
    {
      headerName: t('representIdTableHeader'),
      field: userModel.field9,
      width: convertWidth(listColumPercent.representId),
      floatingFilterComponent: FloatingFilterComponent,
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId } = data;
        const { managedUserId } = data;

        if (representationId === null) {
          return managedUserId;
        }
        return representationId;
      },
    },
    {
      headerName: t('paymentIdTableHeader'),
      field: userModel.field10,
      width: convertWidth(listColumPercent.paymentId),
      floatingFilterComponent: FloatingFilterComponent,
    },
    {
      headerName: t('type'),
      field: userModel.field23,
      width: convertWidth(listColumPercent.type),
      valueFormatter: (params) => valueTranslate(t, params.value, 'type'),
      filter: UseFilter,
      filterParams: {
        title: 'Type Filters',
        values: [
          { value: 0, category: 'type' },
          { value: 1, category: 'type' },
          { value: 2, category: 'type' },
          { value: 3, category: 'type' },
          { value: 4, category: 'type' },
        ],
      },
      floatingFilterComponent: null,
    },
    {
      headerName: t('billingMethodTableHeader'),
      field: userModel.field11,
      width: convertWidth(listColumPercent.billingMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.billingmethod, 'billingMethod'),
      floatingFilterComponent: null,
      filter: UseFilter,
      filterParams: {
        title: 'Billing method Filters',
        values: [
          // { value: 0, category: 'billingMethod' },
          { value: 1, category: 'billingMethod' },
          { value: 2, category: 'billingMethod' },
          { value: 3, category: 'billingMethod' },
        ],
      },
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(billingMethod.inApp), value: 1 },
          { name: t(billingMethod.outside), value: 2 },
          { name: t(billingMethod.special), value: 3 },
        ],
      },
    },
    {
      headerName: t('ratePlanTableHeader'),
      field: userModel.field18,
      width: convertWidth(listColumPercent.ratePlan),
      valueFormatter: (params) => valueTranslate(t, params.data.rateplan, 'ratePlan'),
      floatingFilterComponent: null,
      filter: UseFilter,
      filterParams: {
        title: 'Rate plan Filters',
        values: [
          { value: ratePlan.basic, category: 'ratePlan' },
          { value: ratePlan.prenium, category: 'ratePlan' },
        ],
      },
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(ratePlan.basic), value: ratePlan.basic },
          { name: t(ratePlan.prenium), value: ratePlan.prenium },
        ],
      },
      editable: (params) => console.log(params),
    },
    {
      headerName: t('paymentTableHeader'),
      field: userModel.field19,
      width: convertWidth(listColumPercent.paymentMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.paymentmethod, 'paymentMethod'),
      floatingFilterComponent: null,
      filter: UseFilter,
      filterParams: {
        title: 'Payment Method Filters',
        values: [
          { value: 1, category: 'paymentMethod' },
          { value: 2, category: 'paymentMethod' },
          { value: 3, category: 'paymentMethod' },
          { value: 4, category: 'paymentMethod' },
        ],
      },
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentMethod.inApp), value: 1 },
          { name: t(paymentMethod.credit), value: 2 },
          { name: t(paymentMethod.bank), value: 3 },
        ],
      },
    },
    {
      headerName: t('paymentSpanTableHeader'),
      field: userModel.field21,
      width: convertWidth(listColumPercent.paymentSpan),
      floatingFilterComponent: FloatingFilterComponent,
      // filter: UseFilter,
      // filterParams: {
      //   title: 'Payment Span Filters',
      //   values: [
      //     { value: paymentSpan.singleMonth, category: 'paymentSpan' },
      //     { value: paymentSpan.advanceMonth, category: 'paymentSpan' },
      //     { value: paymentSpan.advanceYear, category: 'paymentSpan' },
      //     { value: paymentSpan.firstTime, category: 'paymentSpan' },
      //   ],
      // },
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentSpan.singleMonth), value: paymentSpan.singleMonth },
          { name: t(paymentSpan.advanceMonth), value: paymentSpan.advanceMonth },
          { name: t(paymentSpan.advanceYear), value: paymentSpan.advanceYear },
          { name: t(paymentSpan.firstTime), value: paymentSpan.firstTime },
        ],
      },
    },
    {
      headerName: t('priceTableHeader'),
      field: userModel.field25,
      width: convertWidth(listColumPercent.price),
      floatingFilterComponent: FloatingFilterComponent,
      cellRenderer: (params) => {
        const { data } = params;
        if (data.price === 0) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>-</div>
          );
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{`¥${data.price}`}</div>
        );
      },
    },
    {
      headerName: t('lastBillingDateTableHeader'),
      field: userModel.field27,
      width: convertWidth(listColumPercent.lastBillingDate),
      floatingFilterComponent: DatePicker,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      cellEditor: DatePickerEditor,
    },
    {
      headerName: t('nextBillingDateTableHeader'),
      field: userModel.field28,
      width: convertWidth(listColumPercent.nextBillingDate),
      floatingFilterComponent: DatePicker,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      cellEditor: DatePickerEditor,
    },
    {
      headerName: t('startDateTableHeader'),
      field: userModel.field12,
      width: convertWidth(listColumPercent.startDate),
      floatingFilterComponent: DatePicker,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
    },
    {
      headerName: t('telephoneTableHeader'),
      field: userModel.field29,
      width: convertWidth(listColumPercent.phone),
      floatingFilterComponent: FloatingFilterComponent,
    },
    // {
    //   headerName: t('withdrawDateTableHeader'),
    //   field: userModel.field30,
    //   width: convertWidth(listColumPercent.withdrawDate),
    //   floatingFilterComponent: FloatingFilterComponent,
    // },
    {
      headerName: t('updateDateTableHeader'),
      field: userModel.field22,
      width: convertWidth(listColumPercent.updateDate),
      floatingFilterComponent: DatePicker,
      floatingFilterComponentParams: { suppressFilterButton: true },
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      cellEditor: DatePickerEditor,
    },
  ];

  const components = useMemo(() => ({
    agDateInput: DatePicker,
  }), []);

  // Configuration for columns
  const configColumn = () => {
    const copyColumn = [...columnDefs];
    if (editable) {
      editable.forEach((number) => copyColumn[number].editable = true);
    }
    if (removeAllColumn) {
      return [];
    }
    if (removeColumn) {
      removeColumn.forEach((number) => copyColumn[number] = 'remove');
      const newCol = copyColumn.filter((cols) => cols !== 'remove');
      return newCol;
    }
    return newCustomeColumn || columnDefs;
  };

  useEffect(() => {
    listNode.forEach((node) => {
      if (listChecked?.includes(node.data.id)) {
        node.setSelected(true);
      }
    });
  }, [listNode, listChecked]);

  const dataTable = {
    className,
    setListNode,
    rowSelection: 'multiple',
    rowMultiSelectWithClick: true,
    customColumnDefs: configColumn(),
    listData: listData || data,
    components,
    ...others,
  };

  const getData = async (filter, limit, offset, direct, checked) => {
    const response = await fetchApi('/api/admin/members/list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        limit,
        offset,
        orderBy: direct.filter((data) => data.direct !== null),
        filterBy: filter.filter((data) => data.value !== null),
      }),
    });
    if (response.ok) {
      const userList = await response.json();
      const transformedUsersData = userList.data.dataList?.map((data) => ({
        ...data,
        billingmethod: data.billing_method,
        userpaymentid: data.user_payment_id,
        lastbillingdate: data.last_billing_date,
        nextbillingdate: data.next_billing_date,
        updatedate: data.update_date,
        paymentmethod: data.payment_method,
        paymentnumber: data.payment_number,
        paymentspan: data.payment_span,
        rateplan: data.rate_plan,
        phone: data.telephone,
      }));
      setTotalPages(userList.total);
      setData(transformedUsersData);
      if (checked === true) {
        dispatch(helperActions.getCheckedList(transformedUsersData));
      } else if (checked === null) {
        dispatch(helperActions.getCheckedList(transformedUsersData));
      } else {
        dispatch(helperActions.getCheckedList([]));
      }
    }

    if (!saveData) {
      dispatch(helperActions.removeAllData());
    }
  };

  // Function to change page
  const handlePageChange = (event, page) => {
    const offsetValue = page * limit - limit;
    setCurrentPage(page);
    dispatch(helperActions.currentPage(page));
    setOffset(offsetValue);
    setLimit(limit);
    setChecked(false);
    getData(filterData, limit, offsetValue, direct);
  };

  useEffect(() => {
    if (checked === true) {
      getData(filterData, 0, 0, direct, true);
    } else if (checked === null) {
      getData(filterData, limit, offset, direct, null);
    } else {
      getData(filterData, limit, offset, direct, false);
    }
  }, [filterData, direct, checked]);

  useEffect(() => {
    if (refreshData === true) {
      getData(filterData, limit, offset, direct, false);
      setRefreshData(false);
    }
  }, [refreshData]);

  return (
    <div className={classes.contentBox} ref={mainRef}>
      <Table {...dataTable} />
      {showPagination === true && (
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#f1f0f0' }}>
        <Pagination
          className={classes.pagination}
          size="small"
          count={count}
          onChange={handlePageChange}
          showFirstButton
          showLastButton
        />
        <TablePagination
          size="small"
          component="div"
          count={totalPages}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={() => {}}
          rowsPerPageOptions={[]}
          nextIconButtonProps={{ style: { display: 'none' } }}
          backIconButtonProps={{ style: { display: 'none' } }}
        />
      </div>
      )}
    </div>
  );
};

export default NewTable;
