/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import fetchApi from '../../../FetchApi';
import Header from '../Header';
import ComfirmationPopup from '../ComfirmationPopup';
import NewTable from '../../../components/NewTable';
import { getUserIds } from '../../../common/utils';
import '../../../../styles/components/SuspensionOrResume.css';
import FormDatePicker from '../../../components/form/FormDatePicker';

const useStyles = makeStyles((theme) => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
  mobileStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const SuspensionOrResumeNew = () => {
  const classes = useStyles();
  const t = useTranslation();
  const { control, watch, reset, formState: { isValid } } = useForm({ mode: 'onChange' });

  const [gridApi, getGridApi] = useState(null);
  const [dataSelect, setDataSelect] = useState([]);
  const [openModalDateSetting, setOpenModalSetting] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const checkedList = useSelector((state) => state.helper.checkedList);

  const userIds = getUserIds(checkedList, dataSelect);

  useEffect(() => {
    if (checkedList.length === 0) {
      setDataSelect([]);
    }
  }, [checkedList]);

  const handleCloseDateSetting = () => setOpenModalSetting(false);

  const handleSubmit = async (status, sendDate = false) => {
    const obj = {
      userIds,
      disable: status,
    };
    if (sendDate) {
      obj.nextBillingDate = watch('nextBillingDate').valueOf();
    }
    const response = await fetchApi('/api/admin/users/change_status', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    });
    if (response.ok) {
      reset();
      setRefreshData(true);
      handleCloseDateSetting();
    }
  };

  const handleSelectionChange = () => {
    setDataSelect(gridApi?.getSelectedRows() || []);
  };

  const handleRowDataUpdated = () => {
    gridApi?.forEachNode((node) => {
      if (checkedList.length !== 0) {
        node.setSelected(true);
      }
    });
  };

  const dataTable = {
    getGridApi,
    refreshData,
    setRefreshData,
    removeColumn: [1],
    floatingFilter: true,
    showPagination: true,
    className: classes.configTable,
    onSelectionChanged: handleSelectionChange,
    onRowDataUpdated: handleRowDataUpdated,
  };

  return (
    <div>
      <Header topic="Suspension Or Resume Account">
        <Typography>{t('mimamoritargetDescription')}</Typography>
      </Header>
      <NewTable {...dataTable} />
      <div className={classes.mobileStyle}>
        <ComfirmationPopup
          handleOption={() => setOpenModalSetting(true)}
          styleButton={{ margin: '15px' }}
          btnColor="#02AA9EB2"
          btnTitle={t('endDateSetting')}
          width="200px"
          confirmText={t('confirmContinue')}
          disabled={userIds.length === 0}
          confirmContinue
        />
        <ComfirmationPopup
          backGroundColor="#EE7357"
          styleButton={{ margin: '15px' }}
          btnColor="#EE7357"
          btnTitle={t('pauseUsing')}
          submit={() => handleSubmit(true)}
          width="200px"
          confirmText={t('notifyChangePausesUser')}
          disabled={userIds.length === 0}
        />
        <ComfirmationPopup
          backGroundColor="#4EC3BB"
          styleButton={{ margin: '15px' }}
          btnColor="#4DB4E1"
          btnTitle={t('continueUsing')}
          submit={() => handleSubmit(false)}
          width="200px"
          confirmText={t('notifyChangeUsingUser')}
          disabled={userIds.length === 0}
          confirmContinue
        />
      </div>
      <Modal
        open={openModalDateSetting}
        onClose={handleCloseDateSetting}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container-modal-date-setting">
          <div className="content-modal">
            <Typography>{t('endDateTitleModal')}</Typography>
            <FormDatePicker
              controllerProps={{
                control,
                name: 'nextBillingDate',
                rules: {
                  required: t('fieldNotNull'),
                  validate: (value) => {
                    if (!value) return true;
                    return moment(value).isBefore(moment(), 'day') ? t('datePre') : true;
                  },
                },
              }}
              dateTimePickerProps={{
                className: 'date-picker',
                format: 'YYYY-MM-DD',
                minDate: moment(),
              }}
            />
          </div>
          <div className="container-button">
            <Button className="btn-cancel" onClick={handleCloseDateSetting}>{t('sharedCancel')}</Button>
            <Button disabled={!isValid} className="btn-perform" onClick={() => handleSubmit(false, true)}>{t('sharedPerform')}</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SuspensionOrResumeNew;
