/* eslint-disable no-undef */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import FilterComponent from './FilterComponent';
import FilterIcon from '../../resources/images/icon/filter-solid.svg';
import './tableStyles.css';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  tableHeight: {
    height: `${window.innerHeight - 120}px`,
  },
  tableConfig: {
    minHeight: '400px',
    width: '100%',
    borderRadius: '5px',
    '& .ag-root-wrapper': {
      border: '1px solid black',
    },
    '& .ag-icon': {
      fontFamily: 'var(--ag-icon-font-family) !important',
    },
    '& .ag-cell': {
      borderLeft: '0.1px solid black',
      borderRight: '0.1px solid black',
      textAlign: 'left',
    },
    '& .ag-header-group-cell ': {
      padding: '0 !important',
    },
    '& .ag-header-row-column-group': {
      overflow: 'visible !important',
    },
    '& .ag-menu': {
      zIndex: 99999,
      overflow: 'visible !important',
    },
    '& .ag-header-row-column': {
      backgroundColor: '#F07000',
      color: 'black',
    },
    '& .ag-header-cell': {
      borderBottom: 'none',
      borderLeft: '0.1px solid black',
      borderRight: '0.1px solid black',
    },
    '& .ag-floating-filter': {
      padding: '0 5px',
      border: '0.25px solid',
    },
    '& .ag-header-cell-label': {
      justifyContent: 'flex-start',
    },
    '& .ag-icon-menu': {
      display: 'none',
    },
    '& .ag-floating-filter-button': {
      margin: '0 4px',
    },
    '& .ag-row-position-absolute': {
      borderBottom: '2px solid black',
      // '& div[col-id="group"], div[col-id="group_1"], div[col-id="group_2"], div[col-id="group_3"], div[col-id="floatingTargetCheckBox"], div[col-id="0"], div[col-id="subManagerId"],div[col-id="managerId_1"], div[col-id="subManagerEmail"], div[col-id="managerId"],div[col-id="id"] ': {
      //   padding: 0,
      // },
      '& div[col-id"id"]': {
        padding: 0,
      },
    },
    '& .ag-body-viewport': {
      // Use momentum-based scrolling on WebKit-based touch devices.
      webkitOverflowScrolling: 'touch',
    },
    '& .ag-paging-panel.ag-unselectable': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },
  },
  fixHeader: {
    '& .ag-header-row-column': {
      backgroundColor: '#F07000',
      color: 'black',
      transform: 'translateY(48px)',
    },
    '& .ag-header-row-column-filter': {
      transform: 'translateY(-48px)',
    },
  },
}));

const Table = (props) => {
  const {
    customColumnDefs, customColDef, childrenTop,
    childrenBottom, listData, singleClickEdit,
    suppressRowClickSelection, onCellEditRequest,
    rowSelection, onCellValueChanged, getTargetArray,
    readOnlyEdit, floatingFilterOff,
    rowMultiSelectWithClick, width, height, style,
    autoResize, className, onSelectionChanged,
    setupOnGridReady, rowHeight, getGridApi, tableRef,
    autoHeight, boxRef, getRowHeight, isRowSelectable, onRowSelected, onRowDataUpdated, components,
  } = props;
  const classes = useStyles();
  const customRef = useRef();

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(customColumnDefs);
  const [gridApi, setGridApi] = useState(null);

  const table = customRef?.current;

  const header = table?.querySelector('div.ag-header-container');

  const [size, setSize] = useState({
    height: '100%',
    width: '100%',
  });

  useEffect(() => {
    const newData = [];
    if (Array.isArray(listData)) {
      listData.forEach((data) => {
        const copyData = { ...data };
        let checkData;
        const sum = 0;
        const arrLength = [];

        if (getTargetArray?.length > 1) {
          const firstTarget = getTargetArray[0];
          checkData = copyData[firstTarget];
          getTargetArray.forEach((e, i) => {
            if (i !== 0) {
              checkData?.forEach((a) => {
                checkData = a[e];
                arrLength.push(checkData.length);
              });
            }
          });
        } else if (getTargetArray) {
          arrLength.push(copyData[getTargetArray[0]]?.length);
        } else {
          arrLength.push(1);
        }

        // arrLength.forEach((value) => sum += value);

        // copyData.rowHeight = sum * elementHeight;
        newData.push(copyData);
      });
    }
    setRowData(newData);
  }, [listData]);

  useEffect(() => {
    if (gridApi && autoResize) {
      gridApi.sizeColumnsToFit();
    }
  }, []);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    if (gridApi && autoResize < windowWidth) {
      gridApi.sizeColumnsToFit();
    }
    if (table?.clientWidth > header?.clientWidth) {
      gridApi.sizeColumnsToFit();
    }
  };

  window.onresize = handleResize;

  const defaultColDef = useMemo(() => ({
    resizable: false,
    suppressMenu: false,
    filter: FilterComponent,
    floatingFilter: !floatingFilterOff,
    suppressMovable: true,
  }), []);

  useEffect(() => {
    setSize({
      width: width || '100%',
      height: height || '100%',
    });
  }, [height, width]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    if (getGridApi) getGridApi(params.api);
    if (setupOnGridReady) setupOnGridReady(params.api);
    if (autoHeight) {
      params.api.setDomLayout('autoHeight');
      // classes.tableConfig.height = '';
    }
  };

  // const gridOptions = {
  //   // ...
  //   getRowNodeId: (data) => data.id,
  // };

  // const onGridReady = (params) => {
  //   setGridApi(params.api);

  //   const selectedDataStr = localStorage.getItem('selectedData');
  //   if (selectedDataStr) {
  //     const selectedData = JSON.parse(selectedDataStr);
  //     const { api } = params;

  //     selectedData.forEach((data) => {
  //       const rowNode = api.getRowNode(data.id);
  //       if (rowNode) {
  //         rowNode.setSelected(true, false);
  //       }
  //     });
  //   }
  // };

  // gridApi?.forEachNode((node) => {
  //   settingsNode(node);
  // });

  useEffect(() => {
    if (table?.clientWidth > header?.clientWidth && gridApi) {
      gridApi.sizeColumnsToFit();
    }
  }, [table, header]);

  const icons = useMemo(() => ({
    filter: `<img src="${FilterIcon}" />`,
  }), []);

  const tabToNextCell = useCallback((params) => {
    const previousCell = params.previousCellPosition;
    const lastRowIndex = previousCell.rowIndex;
    let nextRowIndex = params.backwards ? lastRowIndex - 1 : lastRowIndex + 1;
    const renderedRowCount = params.api.getModel().getRowCount();
    if (nextRowIndex < 0) {
      nextRowIndex = -1;
    }
    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }
    const result = {
      rowIndex: nextRowIndex,
      column: previousCell.column,
      rowPinned: previousCell.rowPinned,
    };
    return result;
  }, []);

  return (
    <>
      {childrenTop}
      <div
        className={`ag-theme-alpine ${classes.tableConfig} ${autoHeight ? '' : classes.tableHeight} ${floatingFilterOff ? '' : classes.fixHeader} ${className}`}
        style={style}
        ref={boxRef || customRef}
      >
        <div style={size}>
          <AgGridReact
            cellrenderer
            rowData={rowData}
            ref={tableRef}
            columnDefs={columnDefs}
            defaultColDef={customColDef || defaultColDef}
            getRowHeight={getRowHeight}
            onCellEditRequest={onCellEditRequest}
            onCellValueChanged={onCellValueChanged}
            singleClickEdit={singleClickEdit}
            suppressRowClickSelection={suppressRowClickSelection}
            readOnlyEdit={readOnlyEdit}
            rowSelection={rowSelection}
            rowMultiSelectWithClick={rowMultiSelectWithClick}
            onGridReady={onGridReady}
            icons={icons}
            animateRows={false}
            suppressColumnVirtualisation
            onSelectionChanged={onSelectionChanged}
            isRowSelectable={isRowSelectable}
            onRowDataUpdated={onRowDataUpdated}
            rowHeight={rowHeight}
            tabToNextCell={tabToNextCell}
            onRowSelected={onRowSelected}
            components={components}
            suppressRowHoverHighlight
            columnHoverHighlight
            stopEditingWhenCellsLoseFocus
            suppressScrollOnNewData
            onCellClicked={(e) => {
              e.eventPath[0].addEventListener('keydown', (e) => {
                if (e.key === 'Delete' || e.keyCode === 46) {
                  e.preventDefault();
                }
              });
            }}
          />
        </div>
      </div>
      {childrenBottom}
    </>
  );
};

export default Table;
