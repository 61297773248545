/* eslint-disable no-undef */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, ListItem, ListItemButton, ListItemText, Snackbar, IconButton,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { helperActions } from '../../store';
import ComfirmationPopup from '../tools/components/ComfirmationPopup';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Header from '../tools/components/Header';
import fetchApi from '../FetchApi';
import NewTable from '../components/NewTable';
import {
  DropdownEdit, convertWidth, valueTranslate,
} from '../common/utils';
import {
  PaymentMethod, RatePlan, PaymentSpan, BillingMethod, PaymentStatus, Member,
} from '../common/DefineClass';
import DatePickerEditor from '../components/DatePickerEditor';

const useStyle = makeStyles(() => ({
  configBtnEdit: {
    backgroundColor: '#C4BFBD',
    color: 'black',
    width: '200px',
    maxWidth: '200px',
    '&:hover': {
      backgroundColor: '#C4BFBD',
    },
  },
  configAddStatus: {
    margin: '0.5rem',
  },
  configTable: {
    height: `${window.innerHeight - 400}px`,
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#f46717',
    },
  },
}));

const listColumPercent = {
  category: 8,
  status: 10,
  name: 12,
  userId: 8,
  disabled: 7,
  email: 20,
  representId: 9,
  paymentId: 22,
  billingMethod: 15,
  ratePlan: 15,
  paymentMethod: 20,
  paymentSpan: 15,
  price: 8,
  lastBillingDate: 10,
  nextBillingDate: 10,
  startDate: 10,
  phone: 10,
  withdrawDate: 10,
  updateDate: 10,
  type: 7,
};

const EditPage = () => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const t = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const userModel = new Member();
  const paymentStatus = new PaymentStatus();
  const billingMethod = new BillingMethod();
  const ratePlan = new RatePlan();
  const paymentMethod = new PaymentMethod();

  const [showListStaus, setShowListStatus] = useState(false);
  const [listUserStatus, setListUserStatus] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const data = useSelector((state) => state.helper.selectedUser);

  const handleClickOutSide = (e) => {
    setShowListStatus(false);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi('/api/admin/payment_status/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        setListUserStatus(objRecive);
      }
    };

    fetchData();
    window.addEventListener('click', handleClickOutSide);
  }, []);

  const defaultColDef = useMemo(() => ({
    width: 150,
    resizable: false,
    suppressMenu: true,
  }), []);

  const editColumnDefs = [
    {
      headerName: t('categoryTableHeader'),
      width: convertWidth(listColumPercent.category),
      field: userModel.field2,
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    },
    {
      headerName: t('statusTableHeader'),
      field: userModel.field3,
      width: convertWidth(listColumPercent.status),
      valueFormatter: (params) => valueTranslate(t, params.data.paymentstatuscode, 'paymentStatus'),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentStatus.newApplication), value: paymentStatus.newApplication },
          { name: t(paymentStatus.continousBilling), value: paymentStatus.continousBilling },
          { name: t(paymentStatus.reminder), value: paymentStatus.reminder },
          { name: t(paymentStatus.cancelationLaw), value: paymentStatus.cancelationLaw },
        ],
      },
      editable: true,
    },
    {
      headerName: t('nameTableHeader'),
      field: userModel.field4,
      width: convertWidth(listColumPercent.name),
      editable: true,
    },
    {
      headerName: t('userIdTableHeader'),
      field: userModel.field5,
      width: convertWidth(listColumPercent.userId),
    },
    {
      headerName: t('userStatusTableHeader'),
      field: userModel.field6,
      width: convertWidth(listColumPercent.disabled),
      valueFormatter: (params) => valueTranslate(t, params.value, 'account'),
    },
    {
      headerName: t('userEmailTableHeader'),
      field: userModel.field7,
      width: convertWidth(listColumPercent.email),
    },
    {
      headerName: t('representIdTableHeader'),
      field: userModel.field9,
      width: convertWidth(listColumPercent.representId),
      cellRenderer: (params) => {
        const { data } = params;
        const { representationId } = data;
        const { managedUserId } = data;

        if (representationId === null) {
          return managedUserId;
        }
        return representationId;
      },
    },
    {
      headerName: t('paymentIdTableHeader'),
      field: userModel.field10,
      width: convertWidth(listColumPercent.paymentId),
    },
    {
      headerName: t('type'),
      field: userModel.field23,
      width: convertWidth(listColumPercent.type),
      valueFormatter: (params) => valueTranslate(t, params.value, 'type'),
    },
    {
      headerName: t('billingMethodTableHeader'),
      field: userModel.field11,
      width: convertWidth(listColumPercent.billingMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.billingmethod, 'billingMethod'),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(billingMethod.inApp), value: 1 },
          { name: t(billingMethod.outside), value: 2 },
          { name: t(billingMethod.special), value: 3 },
        ],
      },
      editable: (params) => (
        params.data[userModel.field2] === 1 ||
        (params.data[userModel.field2] === 0 && moment.utc().valueOf() > moment.utc(params.data[userModel.field28]).valueOf())
      ),
    },
    {
      headerName: t('ratePlanTableHeader'),
      field: userModel.field18,
      width: convertWidth(listColumPercent.ratePlan),
      valueFormatter: (params) => valueTranslate(t, params.data.rateplan, 'ratePlan'),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(ratePlan.basic), value: ratePlan.basic },
          { name: t(ratePlan.prenium), value: ratePlan.prenium },
        ],
      },
      editable: ((params) => (
        params.data[userModel.field2] === 1 ||
          (params.data[userModel.field2] === 0 && (params.data[userModel.field11] === '3' || params.data.billing_method === 3))
      )),
    },
    {
      headerName: t('paymentTableHeader'),
      field: userModel.field19,
      width: convertWidth(listColumPercent.paymentMethod),
      valueFormatter: (params) => valueTranslate(t, params.data.paymentmethod, 'paymentMethod'),
      cellEditor: DropdownEdit,
      cellEditorParams: {
        values: [
          { name: t(paymentMethod.inApp), value: 1 },
          { name: t(paymentMethod.credit), value: 2 },
          { name: t(paymentMethod.bank), value: 3 },
        ],
      },
      editable: ((params) => (
        params.data[userModel.field2] === 1 ||
          (params.data[userModel.field2] === 0 && (params.data[userModel.field11] === '3' || params.data.billing_method === 3))
      )),
    },
    {
      headerName: t('paymentSpanTableHeader'),
      field: userModel.field21,
      width: convertWidth(listColumPercent.paymentSpan),
    },
    {
      headerName: t('priceTableHeader'),
      field: userModel.field25,
      width: convertWidth(listColumPercent.price),
      cellRenderer: (params) => {
        const { data } = params;
        if (data.price === 0) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>-</div>
          );
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>{`¥${data.price}`}</div>
        );
      },
    },
    {
      headerName: t('lastBillingDateTableHeader'),
      field: userModel.field27,
      width: convertWidth(listColumPercent.lastBillingDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      cellEditor: DatePickerEditor,
      editable: ((params) => (
        params.data[userModel.field2] === 1 ||
          (params.data[userModel.field2] === 0 && (params.data[userModel.field11] === '3' || params.data.billing_method === 3))
      )),
    },
    {
      headerName: t('nextBillingDateTableHeader'),
      field: userModel.field28,
      width: convertWidth(listColumPercent.nextBillingDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
      cellEditor: DatePickerEditor,
      editable: ((params) => (
        params.data[userModel.field2] === 1 ||
          (params.data[userModel.field2] === 0 && (params.data[userModel.field11] === '3' || params.data.billing_method === 3))
      )),
    },
    {
      headerName: t('startDateTableHeader'),
      field: userModel.field12,
      width: convertWidth(listColumPercent.startDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
    },
    {
      headerName: t('telephoneTableHeader'),
      field: userModel.field29,
      width: convertWidth(listColumPercent.phone),
      editable: true,
    },
    {
      headerName: t('updateDateTableHeader'),
      field: userModel.field22,
      width: convertWidth(listColumPercent.updateDate),
      cellRenderer: (params) => {
        const inputDate = params.value;
        if (inputDate !== null) {
          const formattedDate = moment(inputDate).local().format('YYYY/MM/DD');
          return formattedDate;
        }
        return null;
      },
    },
  ];

  const onCellValueChanged = useCallback((e) => {
    const dataOnRow = e.data;
    const { field } = e.colDef;
    const { newValue } = e;
    const oldItem = data.find((row) => row.id === dataOnRow.id);
    const newItem = { ...oldItem };

    newItem[field] = newValue;
    const { id, name, email, paymentstatuscode } = newItem;
    const newData = { id, name, email, paymentstatuscode, ...newItem };

    dispatch(helperActions.updateData(newData));
  });

  const handleStatus = (code) => {
    setShowListStatus(false);
    const newData = data.map((item) => ({
      ...item,
      paymentstatuscode: code,
      code,
    }));

    const newItem = newData.map((item) => ({
      id: item.id,
      name: item.name,
      email: item.email,
      payment_status_code: code,
      phone: item.telephone,
      ...item,
    }));
    dispatch(helperActions.pushAllData(newItem));
  };

  const updateData = data.map((item) => ({
    id: item.id,
    name: item.name,
    // email: item.email,
    payment_status_code: item.paymentstatuscode,
    billingMethod: Number(item.billingmethod),
    rateplan: item.rateplan,
    paymentMethod: Number(item.paymentmethod),
    enddate: item.nextbillingdate ? moment.utc(item.nextbillingdate).valueOf() : null,
    lastbillingdate: item.lastbillingdate ? moment.utc(item.lastbillingdate).valueOf() : null,
    phone: item.telephone,
  }));

  const dataTable = {
    listData: data,
    newCustomeColumn: editColumnDefs,
    // removeColumn: [0, 1],
    saveData: true,
    onCellValueChanged,
    singleClickEdit: true,
    editStatus: true,
    customColDef: defaultColDef,
    // editable: [3, 4, 11, 12, 13, 16, 17, 19],
    floatingFilterOff: true,
    className: classes.configTable,
    childrenTop: (
      <>
        <div>
          <Header link={-1} />
        </div>
        <div style={{ position: 'relative', height: '50px' }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: '1',
            backgroundColor: '#E9E9E9',
            borderRadius: '4px',
            boxShadow: '0 0 5px gray',
          }}
          >
            <Button
              className={classes.configBtnEdit}
              onClick={(e) => {
                e.stopPropagation();
                setShowListStatus(!showListStaus);
              }}
            >
              <LaunchIcon style={{ paddingRight: '5px', fontSize: '30px' }} />
              {t('editStatus')}
              {showListStaus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Button>
            {showListStaus && listUserStatus.map((data) => (
              <ListItem disablePadding>
                <ListItemButton
                  key={data.id}
                  value={data.code}
                  className={classes.listItem}
                  onClick={() => handleStatus(data.code)}
                >
                  <ListItemText primary={t(data.code)} />
                </ListItemButton>
              </ListItem>
            ))}
          </div>
        </div>
      </>
    ),
  };
  const hasEmptyName = updateData.some((obj) => obj.name === '');

  return (
    <div>
      <NewTable {...dataTable} />
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: '2rem' }}>
        <ComfirmationPopup
          btnTitle={t('perform')}
          btnColor="#ff0505"
          confirmText={t('confirmEdit1')}
          subConfirmText={t('confirmEdit2')}
          closeModal={hasEmptyName && false}
          submit={async () => {
            if (hasEmptyName) {
              handleOpenSnackbar();
            } else {
              dispatch(helperActions.setLoading(true));

              const response = await fetchApi('/api/admin/payment_status/settings_v2', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(updateData),
              });
              if (response.ok) {
                dispatch(helperActions.setPathname(location.pathname));
                navigate('/admin/finish');
              }
              dispatch(helperActions.setLoading(false));
            }
          }}
        />
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
          message={t('editvalidate')}
          action={action}
        />
      </div>
    </div>
  );
};

export default EditPage;
