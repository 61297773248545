import React, { useEffect, useState } from 'react';
import {
  Button, Card, CardActionArea, CardContent, Grid, Typography, CardActions, IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import Header from '../Header';
import { useCatchCallback } from '../../../../reactHelper';
import { geofencesActions } from '../../../../store';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import fetchApi from '../../../FetchApi';

const url = '/admin/tools';

const useStyles = makeStyles((theme) => ({
  createBtn: {
    backgroundColor: '#27313b',
    border: 'outset 5px #1e2329',
    color: 'white',
    '&:hover': {
      backgroundColor: '#27313b',
      border: 'outset 5px #1e2329',
    },
  },
  itemName: {
    display: 'flex',
    justifyContent: 'center',
    color: 'white',
  },
  cardAction: {
    backgroundColor: '#7f7f7f',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      height: '30px',
    },
  },
  cardActionArea: {
    height: 200,
    backgroundColor: '#222a35',
    borderRadius: '5px 5px 0px 0px',
    '&:hover': {
      backgroundColor: '#7f7f7f',
    },
    [theme.breakpoints.down('md')]: {
      height: '130px',
    },
  },
  noArea: {
    fontWeight: 'bold',
    fontSize: '24px',
    color: '#333f50',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      marginTop: '3rem',
    },
  },
  areaName: {
    fontSize: '24px',
    fontWeight: 'bold',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    // display: '-webkit-box',
    // '-webkit-line-clamp': 3,
    // '-webkit-box-orient': 'vertical',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
}));

const SetupArea = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  const [items, setItem] = useState([]);
  // const items = useSelector((state) => state.geofences.items);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchApi('/api/admin/geofences/list', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const objRecive = await response.json();
        setItem(objRecive);
      }
    };
    fetchData();
  }, [navigate]);

  // console.log(items);
  return (
    <>
      <Header>
        <Typography>{t('setupAreaPageTitle')}</Typography>
      </Header>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '30px 0 30px 30px' }}>
        <Button
          variant="outlined"
          className={classes.createBtn}
          onClick={() => navigate(`${url}/setupArea/createArea`)}
        >
          <AddCircleIcon sx={{ marginRight: '5px', color: 'white' }} />
          <Typography sx={{ fontSize: 14, textDecoration: 'underline' }}>{t('sharedCreateArea')}</Typography>
        </Button>
      </div>
      {items.length > 0 ? (
        <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 8, sm: 8, md: 8, lg: 12, xl: 12 }}>
          {items.map((item) => (
            <Grid item xs={4} sm={4} md={4} lg={3} xl={3} key={item.id}>
              <Card>
                <CardActionArea onClick={() => navigate(`${url}/setupArea/areaDetails/${item.id}`)} className={classes.cardActionArea}>
                  <CardContent className={classes.itemName}>
                    <Typography className={classes.areaName} gutterBottom component="div">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardAction}>
                  <IconButton
                    onClick={() => {
                      navigate(`${url}/setupArea/editArea/${item.id}`);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (<Typography className={classes.noArea}>{t('noArea')}</Typography>)}
    </>
  );
};

export default SetupArea;
