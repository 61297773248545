/* eslint-disable react/no-array-index-key */
import { Breadcrumbs, Link, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { Fragment, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from '../../common/components/LocalizationProvider';

const useStyle = makeStyles(() => ({
  textConfig: {
    fontSize: '1.5rem',
    textDecoration: 'none',
    textTransform: 'capitalize',

  },
  textConfigMobile: {
    fontSize: '1.2rem',
    textDecoration: 'none',
    textTransform: 'capitalize',
  },
  breadcrumbsConfig: {
    borderBottom: '2px solid black',
    display: 'inline-block',
    margin: '1rem 0',
    '& .MuiBreadcrumbs-separator ': {
      '& .MuiSvgIcon-root': {
        width: '2rem',
      },
    },
  },
  breadcrumbsMobileConfig: {
    borderBottom: '2px solid black',
    display: 'inline-block',
    margin: '1rem 0',
    '& .MuiBreadcrumbs-separator ': {
      '& .MuiSvgIcon-root': {
        width: '1rem',
      },
    },
  },
}));
const BreadcrumbsCustom = ({ getAfter }) => {
  const theme = useTheme();
  const location = useLocation();
  const classes = useStyle();
  const t = useTranslation();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = location;

  const arrPath = pathname.split('/');
  const indexTarget = arrPath.findIndex((e) => e === getAfter);
  const listResult = arrPath.filter((v, i) => (!(i <= indexTarget)));

  const configName = (keys) => {
    const listLink = {
      home: t('linkListHome'),
      tools: t('linkListTool'),
      list: t('linkListAccountList'),
      edit: t('linkListEdit'),
      reset: t('linkListReset'),
      setupArea: t('linkListSetUpArea'),
      createArea: t('linkListCreateArea'),
      editArea: t('linkListEditArea'),
      areaDetails: t('linkListAreaDetails'),
      monitorTarget: t('linkListSettingAccount'),
      groupSettings: t('linkListGroupSettings'),
      changepassword: t('linkListChangePassword'),
      mimamoritarget: t('linkListMimamoriTarget'),
      manager: t('linkListManager'),
      movingAccount: t('linkListMovingAccount'),
      suspensionOrResume: t('linkListSuspensionOrResume'),
      sendnotifications: t('linkListSendNotifications'),
      confirm: t('linkListConfirm'),
      representAccount: t('linkListRepresentAccount'),
      settingAccount: t('linkListSettingAccount'),
      changePasswordForm: t('changePasswordForm'),
      details: t('linkListDetails'),
      history: t('linkListHistory'),
      accountSetting: t('accountSetting'),
    };
    return listLink[keys] || keys;
  };

  const renderItem = () => listResult.map((v, i) => {
    const target = arrPath.findIndex((e) => e === v);
    const link = arrPath.slice(0, target + 1).join('/');
    return (
      <div key={i}>
        {listResult.length - 1 !== i ? (
          <div className={desktop ? classes.textConfig : classes.textConfigMobile}>{configName(v)}</div>
        ) : (
          <div className={desktop ? classes.textConfig : classes.textConfigMobile}>{configName(v)}</div>
        )}
      </div>
    );
  });
  return (
    <Breadcrumbs separator={<ArrowForwardIosIcon />} aria-label="breadcrumb" className={desktop ? classes.breadcrumbsConfig : classes.breadcrumbsMobileConfig}>
      {renderItem()}
    </Breadcrumbs>
  );
};

export default React.memo(BreadcrumbsCustom);
