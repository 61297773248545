import React, { useEffect, useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, FormControl, MenuItem, TextField, tableCellClasses, Snackbar, IconButton,
  Radio,
  FormControlLabel,
  RadioGroup,
  Button,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../Header';
import ConfirmationPopup from '../ComfirmationPopup';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import { useCatch } from '../../../../reactHelper';
import { BillingMethod, RatePlan, PaymentMethod } from '../../../common/DefineClass';
import { helperActions } from '../../../../store';
import { snackBarDurationVeryLongMs } from '../../../../common/util/duration';
import CustomDatePicker from '../../../components/CustomDatePicker';
import CustomInputComponent from '../../../components/CustomInputComponent';
import ModalConfirmAdmin from '../../../modal/ModalConfirm';
import { billingMethod, paymentMethod } from '../../../common/const';
import Vectorback from '../../../../resources/images/Vectorback.png';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#333f50',
    border: '1px solid black',
    color: theme.palette.common.white,
  },
}));

const useStyle = makeStyles({
  required: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'red',
      position: 'relative',
      top: '0',
    },
    '&.MuiButtonBase-root': { backgroundColor: 'red' },
  },
  autofill: {
    fontSize: '20px',
    '&::before': {
      content: '"*"',
      color: 'white',
      position: 'relative',
      top: '0',
    },
  },
  backBtn: {
    backgroundColor: '#fff',
    display: 'flex',
    color: '#7f7f7f',
    border: '1px solid #7f7f7f',
    width: 'fit-content',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px',
  },
  createBtn: {
    backgroundColor: '#02AA9EB2',
    display: 'flex',
    color: 'white',
    width: 'fit-content',
    justifyContent: 'space-evenly',
    fontSize: '16px',
    borderRadius: '20px',
    marginLeft: 30,
    padding: '20px',
    '&:disabled': {
      backgroundColor: 'grey',
    },
    '&:hover': {
      backgroundColor: '#02AA9EB2',
      color: 'white',
    },
  },
  borderCell: {
    height: '100px',
    border: '1px solid black',
  },
  selectField: {
    '&:hover': { backgroundColor: '#de560a' },
    '&:focus': { backgroundColor: '#de560a' },
  },
  datePicker: {
    border: '1px solid #ced4da',
    borderRadius: '4px',
    padding: '8px',
    width: '120px',
    fontWeight: 400,
    fontSize: '1rem',
  },
});

const RepresentAccount = () => {
  const navigate = useNavigate();
  const t = useTranslation();
  const classes = useStyle();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = useMediaQuery('(max-width:600px)');
  const centerStyle = isMobile ? { position: 'absolute', left: '0', right: '0', margin: 'auto', bottom: '30px', width: '200px' } : { position: 'absolute', right: '60px', margin: '30px' };

  const billing = new BillingMethod();
  const rate = new RatePlan();
  const payment = new PaymentMethod();

  const [category, setCategory] = useState(1);
  const [paymentStatusCode, setPaymentStatusCode] = useState('newApplication');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');

  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [statusTime, setStatusTime] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [idRedirect, setIdRedirect] = useState(null);

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate('/admin/tools');
  };
  const handleChangeRadioOption = (event, params) => {
    setStatusTime(parseInt(event.target.value, 10));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
  const handleActionDetailEditTable = () => {
    if (idRedirect) {
      navigate(`/admin/home/list/details/${idRedirect}/edit`);
    }
  };
  const handleCreateAccount = useCatch(async () => {
    const response = await fetch('/api/admin/members/register/manager', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        category,
        paymentStatusCode: 'newApplication',
        name,
        disabled,
        email,
        enddate: (startDate instanceof Date ? startDate : new Date(startDate)).getTime(),
        billingMethod: category === 1 ? billingMethod[1].value : billingMethod[0].value,
        lastBillingDate: moment(startDate).format('YYYY-MM-DD'),
        nextBillingDate: statusTime === 0 ? moment(endDate).format('YYYY-MM-DD') : null,
        paymentMethod: category === 1 ? paymentMethod[1].value : paymentMethod[0].value,
        deviceGPS: false,
        category1: 0,
        category2: 0,
        rateplan: 'PREMIUM',
        typeApp: null,
        note,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      dispatch(helperActions.setPathname(location.pathname));
      if (result.status === 'NG' && result.error !== null) {
        setOpen(true);
        setErrorMessage(result.error);
      } else {
        setOpenModal(true);
        setIdRedirect(result?.data?.id);
      }
    }
  });

  const handleChange = (event, callback) => {
    callback(event.target.value);
  };

  useEffect(() => {
    if (category === 0) {
      setStartDate(Date.now());
      setEndDate(Date.now());
    }
  }, [category]);

  const emailRegex = /^(?!.*\.\.)(?!^\.)[a-zA-Z0-9]+([._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  const specialDisable = !(name.length !== 0 && category !== undefined && disabled !== '' && email.length !== 0 && emailRegex.test(email) && startDate !== null && note.length !== 0 && statusTime !== undefined);

  return (
    <div>
      <Header noDescription noBack topic="Create a manager account">
        <p style={{ fontWeight: 700, fontSize: '20px', margin: '2px 0' }}>{t('specicalAccountPageTitle')}</p>
        <div style={{ margin: '2px 0', display: 'flex' }}>
          <p>{t('descriptionHeaderRepresetive')}</p>
          <Button className={classes.backBtn} onClick={() => navigate('/admin/tools')}>
            <img style={{ marginRight: '10px' }} src={Vectorback} alt="icon back" />
            {t('back')}
          </Button>
          <Button disabled={specialDisable} className={classes.createBtn} onClick={() => handleCreateAccount()}>
            {t('sharedPerform')}
          </Button>
        </div>
      </Header>
      <ModalConfirmAdmin
        openModal={openModal}
        handleClose={handleCloseModal}
        action={handleActionDetailEditTable}
        propsModal={{
          confirm: t('messageRedirectCreateAccountMember'),
          titleCancel: t('sharedNo'),
          titleContinue: t('sharedYes'),
        }}
      />
      <div style={{ margin: '20px' }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">{ t('categoryTableHeader1')}</StyledTableCell>
                <StyledTableCell align="center">{t('categoryTableHeader2')}</StyledTableCell>
                <StyledTableCell align="center">{t('categoryTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('nameTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userIdTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userStatusTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('userEmailTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('startDateTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('endDateTableHeader')}</StyledTableCell>
                <StyledTableCell align="center">{t('noteTableHeader')}</StyledTableCell>

                {/* <StyledTableCell align="center">{t('paymentIdTableHeader')}</StyledTableCell> */}
                {/* {category === 1 && (
                  <>
                    <StyledTableCell align="center">{t('billingMethodTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('ratePlanTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('paymentTableHeader')}</StyledTableCell>
                    <StyledTableCell align="center">{t('expiryDateTableHeader')}</StyledTableCell>
                  </>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '110px' }}>{t('smartPhone')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '110px' }}>{t('representative')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <Select
                      labelId="select-category"
                      id="status"
                      sx={{ width: '120px' }}
                      value={category}
                      onChange={(e) => handleChange(e, setCategory)}
                    >
                      <MenuItem className={classes.selectField} value={0}>{t('accountGeneral')}</MenuItem>
                      <MenuItem className={classes.selectField} value={1}>{t('accountSpecial')}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>

                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value={name}
                      autoComplete="name"
                      onChange={(e) => handleChange(e, setName)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <Typography sx={{ width: '200px' }}>{t('autoGenarateId')}</Typography>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <Select
                      labelId="select-disabled"
                      id="disabled"
                      sx={{ width: '120px' }}
                      value={disabled}
                      onChange={(e) => handleChange(e, setDisabled)}
                    >
                      <MenuItem className={classes.selectField} value>{t('stopped')}</MenuItem>
                      <MenuItem className={classes.selectField} value={false}>{t('inUse')}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '200px' }}
                      name="email"
                      value={email}
                      autoComplete="email"
                      onChange={(e) => handleChange(e, setEmail)}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl sx={{ width: '160px' }} fullWidth>
                    <DatePicker
                      portalId="root"
                      selected={startDate}
                      dateFormat="yyyyMMdd"
                      className={classes.datePicker}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(selectedDate) => {
                        setStartDate(selectedDate);
                      }}
                      customInput={<CustomInputComponent />}
                    />
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl sx={{ width: '196px !important' }} fullWidth>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e) => handleChangeRadioOption(e)}
                    >
                      <FormControlLabel value={1} control={<Radio color="success" />} label={t('unLimited')} />
                      <FormControlLabel value={0} control={<Radio color="success" />} label={t('limited')} />

                      {statusTime === 0 && (
                      <FormControl fullWidth>
                        <DatePicker
                          portalId="root"
                          selected={endDate}
                          dateFormat="yyyyMMdd"
                          className={classes.datePicker}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={(selectedDate) => {
                            setEndDate(selectedDate);
                          }}
                          customInput={<CustomInputComponent />}
                        />
                      </FormControl>
                      )}
                    </RadioGroup>
                  </FormControl>
                </TableCell>
                <TableCell className={classes.borderCell}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="note"
                      value={note}
                      onChange={(e) => handleChange(e, setNote)}
                    />
                  </FormControl>
                </TableCell>
                {/* <TableCell className={classes.borderCell}>
                  <FormControl fullWidth >
                    <TextField
                      required
                      sx={{ width: '180px' }}
                      name="name"
                      value="tre-app-123456789"
                      autoComplete="name"
                      autoFocus
                      // onChange={(event) => setName(event.target.value)}
                      disabled
                    />
                  </FormControl>
                </TableCell> */}
                {/* {category === 1 && (
                <>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-billing-method"
                        id="billingMethod"
                        sx={{ width: '120px' }}
                        value={billingMethod}
                        onChange={(e) => handleChange(e, setBillingMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(billing.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(billing.outside)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(billing.special)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-rate-plan"
                        id="ratePlan"
                        sx={{ width: '120px' }}
                        value={ratePlan}
                        onChange={(e) => handleChange(e, setRatePlan)}
                      >
                        <MenuItem className={classes.selectField} value={rate.basic}>{t(rate.basic)}</MenuItem>
                        <MenuItem className={classes.selectField} value={rate.prenium}>{t(rate.prenium)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <Select
                        labelId="select-payment-method"
                        id="paymentMethod"
                        sx={{ width: '120px' }}
                        value={paymentMethod}
                        onChange={(e) => handleChange(e, setPaymentMethod)}
                      >
                        <MenuItem className={classes.selectField} value={1}>{t(payment.inApp)}</MenuItem>
                        <MenuItem className={classes.selectField} value={2}>{t(payment.credit)}</MenuItem>
                        <MenuItem className={classes.selectField} value={3}>{t(payment.bank)}</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.borderCell}>
                    <FormControl fullWidth>
                      <DatePicker
                        portalId="root"
                        selected={date}
                        dateFormat="yyyy/MM/dd"
                        className={classes.datePicker}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(selectedDate) => {
                          setDate(selectedDate?.getTime());
                        }}
                      />
                    </FormControl>
                  </TableCell>
                </>
                )} */}
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {/* <ConfirmationPopup
        btnTitle={t('sharedCreateSpecialAccount')}
        submit={handleCreateAccount}
        confirmText={t('confirmCreateAccount')}
        btnColor="#f91414"
        width="330px"
        disabled={category === 1 ? specialDisable : generalDisable}
        styleButton={centerStyle}
      /> */}
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={snackBarDurationVeryLongMs}
        message={t(errorMessage)}
        action={action}
      />
    </div>
  );
};

export default RepresentAccount;
