/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from '../../../../common/components/LocalizationProvider';
import Header from '../Header';
import NewTable from '../../../components/NewTable';
import { helperActions } from '../../../../store';

const useStyles = makeStyles(() => ({
  configTable: {
    height: `${window.innerHeight - 200}px`,
  },
}));

const ChangePasswordNew = () => {
  const classes = useStyles();
  const t = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(helperActions.getChangePasswordUser({}));
  }, []);

  const dataTable = {
    removeColumn: [0, 1],
    className: classes.configTable,
    floatingFilter: true,
    changePasswordById: true,
    showPagination: true,
  };

  return (
    <>
      <Header>
        <Typography>{t('changePasswordDescription')}</Typography>
      </Header>
      <NewTable {...dataTable} />
    </>
  );
};

export default ChangePasswordNew;
