import {
  Box, Button, Modal, Typography, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import React from 'react';
import { useTranslation } from '../../common/components/LocalizationProvider';
import Vector from '../../resources/images/Vector.png';

const ModalSuccess = ({ openSuccess, handleClose, title }) => {
  const t = useTranslation;

  return (
    <Modal
      open={openSuccess}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          backgroundColor: '#02AA9E',
          transform: 'translate(-50%, -50%)',
          width: 450,
          height: 250,
          boxShadow: 24,
          p: 5,
          borderRadius: 2, // Optional: for rounded corners
          overflow: 'hidden', // Optional: to prevent overflow of icon
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          height: '100%', // Ensure it takes full height for centering
        }}
        >
          <img style={{ marginBottom: '20px' }} src={Vector} alt="icon success" />
          <Typography className="upsert-device-modal-title">{title}</Typography>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalSuccess;
