/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import React, {
  forwardRef, useImperativeHandle, useState, useEffect, useRef,
} from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import CryptoJS from 'crypto-js';
import {
  Member, PaymentStatus, BillingMethod, RatePlan, PaymentMethod, PaymentSpan, Type, CategoryAccount, ApplicationType,
} from './DefineClass';

const { REACT_APP_SECRETKEY, REACT_APP_IVKEY } = process.env;

const key = CryptoJS.enc.Utf8.parse(REACT_APP_SECRETKEY); // key
const iv = CryptoJS.enc.Utf8.parse(REACT_APP_IVKEY); // optional

const billingMethod = new BillingMethod();
const paymentStatus = new PaymentStatus();
const categoryAccount = new CategoryAccount();
const applicationType = new ApplicationType();
const paymentMethod = new PaymentMethod();
const paymentSpan = new PaymentSpan();
const ratePlan = new RatePlan();
const member = new Member();
const type = new Type();

// Function to convert width
export const convertWidth = (percent) => {
  const defaultWidth = 1400;
  return (defaultWidth * percent) / 100;
};

// function transforms the value returned from the api for link
export const linkValueFormatter = (params) => `http://maps.google.com/maps?q=${params.latitude},${params.longitude}`;

// function to format the value
export const valueTranslate = (t, value, category) => {
  switch (category) {
    case 'account':
      switch (value) {
        case 0:
          return t('accountGeneral');
        case 1:
          return t('accountSpecial');
        case false:
        case 'false':
          return t('inUse');
        case true:
        case 'true':
          return t('stopped');
        default:
          return '';
      }
    case 'category1':
      switch (value) {
        case 0:
          return t('smartPhone');
        case 1:
          return t('gps');
        default:
          return '';
      }
    case 'category2':
      switch (value) {
        case 0:
          return t('representative');
        case 1:
          return t('member');
        default:
          return '';
      }
    case 'statusUsage':
      switch (value) {
        case false:
        case 'false':
          return t('inUse');
        case true:
        case 'true':
          return t('stopped');
        default:
          return '';
      }
    case 'billingMethod':
      switch (value) {
        case 1:
        case '1':
          return t(billingMethod.inApp);
        case 2:
        case '2':
          return t(billingMethod.outside);
        case 3:
        case '3':
          return t(billingMethod.special);
        default:
          return '';
      }
    case 'paymentStatus':
      switch (value) {
        case paymentStatus.newApplication:
          return t(paymentStatus.newApplication);
        case paymentStatus.continousBilling:
          return t(paymentStatus.continousBilling);
        case paymentStatus.reminder:
          return t(paymentStatus.reminder);
        case paymentStatus.cancelationLaw:
          return t(paymentStatus.cancelationLaw);
        default:
          return '';
      }
    case 'paymentMethod':
      switch (value) {
        case 1:
        case '1':
          return t(paymentMethod.inApp);
        case 2:
        case '2':
          return t(paymentMethod.credit);
        case 3:
        case '3':
          return t(paymentMethod.bank);
        case 4:
        case '4':
          return t(paymentMethod.other);
        default:
          return '';
      }
    case 'paymentSpan':
      switch (value) {
        case paymentSpan.singleMonth:
          return t(paymentSpan.singleMonth);
        case paymentSpan.advanceMonth:
          return t(paymentSpan.advanceMonth);
        case paymentSpan.advanceYear:
          return t(paymentSpan.advanceYear);
        case paymentSpan.firstTime:
          return t(paymentSpan.firstTime);
        default:
          return '';
      }
    case 'ratePlan':
      switch (value) {
        case ratePlan.basic:
          return t(ratePlan.basic);
        case ratePlan.prenium:
          return t(ratePlan.prenium);
        default:
          return '';
      }
    case 'type':
      switch (value) {
        case 0:
          return t('local');
        case 1:
          return t('line');
        case 2:
          return t('yahoojp');
        case 3:
          return t('google');
        case 4:
          return t('apple');
        default:
          return '';
      }
    case 'categoryAccount':
      switch (value) {
        case categoryAccount.accountGeneral:
          return t('accountGeneral');
        case categoryAccount.accountSpecial:
          return t('accountSpecial');
        default:
          return '';
      }
    case 'applicationType':
      switch (value) {
        case 0:
        case applicationType.mail:
          return t('mail');
        case 1:
        case applicationType.line:
          return t('line');
        case 2:
        case applicationType.ios:
          return t('ios');
        default:
          return '';
      }
    case 'typeApp':
      switch (value) {
        case applicationType.mail:
          return 0;
        case applicationType.line:
          return 1;
        case applicationType.ios:
          return 2;
        default:
          return '';
      }
    default:
      return '';
  }
};

export const DropdownEdit = forwardRef((props, ref) => {
  const { values, value } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  const [done, setDone] = useState(false);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => ({
    getValue() {
      return selectedValue;
    },
  }));

  const onChangeHandler = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    setDone(true);
  };

  useEffect(() => {
    if (done) props.stopEditing();
  }, [done]);

  return (
    <select
      value={selectedValue}
      style={{ width: '100%', height: '100%' }}
      onChange={(e) => {
        onChangeHandler(e);
      }}
    >
      {values.map((item) => (
        <option key={item.value || index} value={item.value}>{item.name}</option>
      ))}
    </select>
  );
});

export const encrypted = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
    {
      keySize: 128 / 8,
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
  return encryptedData.toString();
};

//
export const getUserIds = (checkedList, dataSelect) => {
  let userIds;

  if (checkedList.length === 0 || (dataSelect.length < checkedList.length)) {
    userIds = dataSelect.map((user) => user.id);
  } else {
    userIds = checkedList.map((user) => user.id);
  }

  return userIds;
};

export const getUserData = (checkedList, dataSelect) => {
  if (checkedList.length === 0 || (dataSelect.length < checkedList.length)) {
    return dataSelect;
  }
  return checkedList;
};

export const renderTimeOptions = (start, maxTime) => {
  const options = [];
  // eslint-disable-next-line no-plusplus
  for (let time = start; time <= maxTime; time++) {
    const timeFormatted = time < 10 ? `0${time}` : `${time}`;
    options.push(
      <MenuItem key={time} value={time} divider>
        {timeFormatted}
      </MenuItem>,
    );
  }
  return options;
};

export const parserTime = (time) => {
  const parts = time.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parts[1] ? parseInt(parts[1], 10) : 0;

  return { hours, minutes };
};
