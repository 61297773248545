import {
  Box, Button, Modal, Typography,
} from '@mui/material';
import React from 'react';
import warning from '../../resources/images/warning.png';
// eslint-disable-next-line import/no-extraneous-dependencies

const ModalConfirm = ({ openModal, handleClose, propsModal, textAcceptButton, action }) => {
  const { confirm, msgWarning, titleCancel, titleContinue } = propsModal;
  return (
  // eslint-disable-next-line react/jsx-filename-extension
    <div className="form-text-field">
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            borderRadius: '10px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 550,
            height: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <img width="70px" height="70px" src={warning} alt="img" />
            <Typography sx={{ width: '85%', textAlign: 'center', mt: 2, mb: 1 }}>{confirm}</Typography>
            <br />
            {msgWarning && <Typography className="upsert-device-modal-title">{msgWarning}</Typography>}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
              className="upsert-device-modal-content"
            >
              <Button
                variant="contained"
                sx={{ mt: 1, mb: 2, width: 'fit-content', minWidth: '100px', marginRight: '40px', backgroundColor: '#818181' }}
                onClick={handleClose}
              >
                {titleCancel}
              </Button>
              <Button
                className="submit-button"
                variant="contained"
                onClick={() => {
                  handleClose();
                  action();
                }}
                sx={{ mt: 1, mb: 2, width: 'fit-content', minWidth: '100px', backgroundColor: '#02AA9EB2' }}
              >
                {textAcceptButton || titleContinue}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
